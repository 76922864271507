import { FieldDefinition } from '../models/field-definition';

export abstract class CdmConstants {

	public static readonly FIELDS_PROJECT: {
		[key: string]: FieldDefinition
	} = {
			PROJECT_TITLE: { name: 'cdm_project_title', detailMain: true, columnIndex: 1 },
			PROJECT_ID: { name: 'cdm_project_id_number', detailMain: true, columnIndex: 2 },
			PROCESS_STATUS: { name: 'cdm_process_status', detailMain: true },
			SERVICE_TYPE: { name: 'cdm_type', detailMain: true, columnIndex: 3 },
			SCHEME: { name: 'cdm_scheme', detailMain: true, columnIndex: 4 },
			HOST_COUNTRY: { name: 'cdm_host_country', detailMain: true, columnIndex: 5 },
			LEAD_VERIFIER: { name: 'cdm_lead_verifier', detailMain: true },
			PROJECT_ITR: { name: 'cdm_itr', detailMain: true },
			OBJECT_NAME: { name: 'object_name', detailMain: true, columnIndex: 6 },
			DOCTYPE_NAME: { name: 'cdm_doctype_name', detailMain: true, columnIndex: 7 },
			LAST_VERSION: { name: 'is_last_version', detailMain: true },
			CONTRACT_NUMBER: { name: 'cdm_contract_number', detailMain: true },
			DATE_REGISTRATION: { name: 'cdm_registration_date', detailMain: true },
			VERSION_LABEL: { name: 'r_version_label', detailMain: true, columnIndex: 8 },
			CREATE_DATE: { name: 'r_creation_date', detailMain: true }
		};

	public static readonly FIELDS_PROPOSAL: {
		[key: string]: FieldDefinition
	} = {
			ACTIS_CODE: { name: 'actis_code', detailMain: true, columnIndex: 1 },
			PROCESS_STATUS: { name: 'cdm_process_status', detailMain: true },
			SERVICE_TYPE: { name: 'cdm_type', detailMain: true, columnIndex: 2 },
			SCHEME: { name: 'cdm_scheme', detailMain: true, columnIndex: 3 },
			PROPOSAL_PROJECT_NAME: { name: 'cdm_proposal_project_name', detailMain: true, columnIndex: 4 },
			CLIENT_NAME: { name: 'cdm_client_name', detailMain: true, columnIndex: 5 },
			PROJECT_TYPE: { name: 'cdm_project_type', detailMain: true, columnIndex: 6 },
			HOST_COUNTRY: { name: 'cdm_host_country', detailMain: true, columnIndex: 7 },
			METHOD: { name: 'cdm_method', detailMain: true },
			OBJECT_NAME: { name: 'object_name', detailMain: true, columnIndex: 8 },
			DOCTYPE_NAME: { name: 'cdm_doctype_name', detailMain: true, columnIndex: 9 },
			LAST_VERSION: { name: 'is_last_version', detailMain: true },
			VERSION_LABEL: { name: 'r_version_label', detailMain: true, columnIndex: 10 },
			CREATE_DATE: { name: 'r_creation_date', detailMain: true }
		};
}
