import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { BvAlertController } from '@bv/ionic';
import { Observable } from 'rxjs';
import { CdmConstants } from '../../../constants/cdm';
import { CdmDocumentTypeEnum } from '../../../models/cdm/cdm-document-type.enum';
import { CdmService } from '../../../providers/services/business/cdm.service';
import { BaseFilterComponent } from '../../base-filter-component';

export class CdmFilterModel {
	public serviceType?: string;
	public projectTitle?: string;
	public projectId?: string;
	public leadVerifier?: string;
	public itr?: string;
	public contractNumber?: string;
	public hostCountry?: string;
	public registrationDate?: string;
	public doctypeName?: string;
	public isLastVersion?: string;
	public scheme?: string;
	public proposalProjectName?: string;
	public clientName?: string;
}

@Component({
	selector: 'app-cdm-filter',
	templateUrl: 'cdm-filter.component.html'
})
export class CdmFilterComponent extends BaseFilterComponent<CdmFilterModel> implements OnInit {

	// Injections
	public readonly cdmSrv = this.injector.get<CdmService>(CdmService);
	private readonly alertCtrl = this.injector.get<BvAlertController>(BvAlertController);

	// Input/Output
	@Input()
	public documentType?: CdmDocumentTypeEnum;
	@Output()
	public search = new EventEmitter();

	public serviceTypes?: Observable<(number | string)[]>;
	public leadVerifiers?: Observable<(number | string)[]>;
	public hostCountries?: Observable<(number | string)[]>;
	public lastVersions?: Observable<(number | string)[]>;
	public itrs?: Observable<(number | string)[]>;

	constructor(protected readonly injector: Injector) {
		super(injector, new CdmFilterModel(), 'CdmFilterComponent');
		this.model.leadVerifier = this.NONEVALUES.STRING;
		this.model.serviceType = this.NONEVALUES.STRING;
		this.model.hostCountry = this.NONEVALUES.STRING;
		this.model.isLastVersion = this.NONEVALUES.STRING;
	}

	ngOnInit(): void {
		if (!this.documentType) throw new Error('Document type is not defined');
		this.serviceTypes = this.cdmSrv.getFilterData(this.documentType, CdmConstants.FIELDS_PROJECT.SERVICE_TYPE.name, this.NONEVALUES.STRING);
		this.lastVersions = this.cdmSrv.getFilterData(this.documentType, CdmConstants.FIELDS_PROJECT.LAST_VERSION.name, this.NONEVALUES.STRING);
		this.hostCountries = this.cdmSrv.getFilterData(this.documentType, CdmConstants.FIELDS_PROJECT.HOST_COUNTRY.name, this.NONEVALUES.STRING);
		if (this.documentType === CdmDocumentTypeEnum.PROJECT) {
			this.leadVerifiers = this.cdmSrv.getFilterData(this.documentType, CdmConstants.FIELDS_PROJECT.LEAD_VERIFIER.name, this.NONEVALUES.STRING);
			this.itrs = this.cdmSrv.getFilterData(this.documentType, CdmConstants.FIELDS_PROJECT.PROJECT_ITR.name, this.NONEVALUES.STRING);
		}
	}

	public onClickSearch(): void {
		// Update none values
		this.logger.debug('onClickSearch', this.model);
		this.prepareDropdownStringValue('serviceType', 'leadVerifier', 'hostCountry', 'isLastVersion');
		// If no filter defined
		if (this.filterIsEmpty()) {
			this.logger.debug('model', this.model);
			this.alertCtrl
				.create({
					header: this.translateSrv.instant('gsit.common.filter.nofilters.alert.title'),
					message: this.translateSrv.instant('gsit.common.filter.nofilters.alert.message'),
					buttons: [
						{
							text: this.translateSrv.instant('gsit.common.filter.nofilters.alert.button'),
							cssClass: 'primary'
						}
					]
				});
		} else {
			this.search.emit(this.model);
		}
	}
}
