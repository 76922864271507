export abstract class GsitConstants {

	public static readonly FIELDS = {
		PROGRAM_CODE: 'bv_program_code',
		YEAR: 'bv_year',
		RFC_NUM: 'bv_rfc_num',
		SHIPMENT_NUM: 'bv_shipment_num',
		IMPORTER: 'bv_importer',
		EXPORTER: 'bv_exporter',
		OBJECT_TYPE: 'r_object_type'
	};
}
