import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppSharedModule } from 'src/app/app-shared.module';
import { AuditorAssessmentAssessmentComponent } from './aa-assessment/aa-assessment.component';
import { AuditorAssessmentFilterComponent } from './aa-filter/aa-filter.component';
import { AuditorAssessmentMetadataComponent } from './aa-metadata/aa-metadata.component';

@NgModule({
	declarations: [
		AuditorAssessmentFilterComponent,
		AuditorAssessmentMetadataComponent,
		AuditorAssessmentAssessmentComponent
	],
	imports: [
		AppSharedModule,
		IonicModule
	],
	exports: [
		AuditorAssessmentFilterComponent,
		AuditorAssessmentMetadataComponent,
		AuditorAssessmentAssessmentComponent
	]
})
export class AuditorAssessmentComponentsModule { }
