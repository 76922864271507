import { Injectable, Injector } from "@angular/core";
import { map, Observable } from "rxjs";
import { AwiFilterModel } from "../../../components/awi/awi-filter/awi-filter.component";
import { WebServices } from "../../../constants/webservices";
import { ApiResult } from "../../../models/api-result";
import { AwiDocumentModel } from "../../../models/awi/awi-awidoc-document.model";
import { HttpService } from "../../core/http/http.service";
import { BaseService } from "../base-service";
import { ApiService } from "../tech/api.service";
import { AutocompleteInterfaceService } from "./autocomplete.service";

@Injectable()
export class AwiService
	extends BaseService
	implements AutocompleteInterfaceService
{
	//Injections
	private readonly httpSrv = this.injector.get<HttpService>(HttpService);
	private readonly apiSrv = this.injector.get<ApiService>(ApiService);

	//constructor
	constructor(protected readonly injector: Injector) {
		super(injector, "AwiService");
	}

	public getFilterData(field: string): Observable<(string | number)[]> {
		return this.httpSrv
			.call(
				WebServices.parametrize(WebServices.AWI.GET_FILTERDATA, field)
			)
			.pipe(
				map((res: ApiResult<AwiDocumentModel>) =>
					this.apiSrv.extractAggregation(res)
				)
			);
	}

	public getAll(
		filters: AwiFilterModel
	): Observable<ApiResult<AwiDocumentModel>> {
		return this.httpSrv.call(
			WebServices.parametrize(WebServices.AWI.GET_ALL, filters)
		);
	}

	public download(model: AwiDocumentModel): Observable<string> | undefined {
		return this.httpSrv.call(
			WebServices.parametrize(WebServices.AWI.GET_DOWNLOAD, model)
		);
	}

	public getAutocompleteData(
		field: string,
		value: any
	): Observable<(string | number)[]> {
		return this.httpSrv
			.call(
				WebServices.parametrize(
					WebServices.AWI.GET_AUTOCOMPLETEDATA,
					field,
					value
				)
			)
			.pipe(
				map((res: ApiResult<any>) =>
					this.apiSrv.extractAggregation(res)
				)
			);
	}

	public apiResultToModels(
		res: ApiResult<AwiDocumentModel>
	): AwiDocumentModel[] {
		return res.hits.hits.map((hit) => {
			const res = hit._source;
			res.id = hit._id;
			return res;
		});
	}
}
