import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { StringUtils } from '@bv/angular-commons';
import { BaseComponent } from '../../base-component';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { EtqConstants } from '../../../constants/etq';
import { EtqDocumentTypeEnum } from '../../../models/etq/etq-document-type.enum';
import { BvAlertController } from '@bv/ionic';
import { BaseFilterComponent } from '../../base-filter-component';

export class EtqFilterModel {
    auditRef: string | undefined = undefined;
    incidentRef: string | undefined = undefined;
    complaintRef: string | undefined = undefined;
    checklistRef: string | undefined = undefined;
    caRef: string | undefined = undefined;
    actionRef: string | undefined = undefined;
}

@Component({
    selector: 'app-etq-filter',
    templateUrl: 'etq-filter.component.html'
})
export class EtqFilterComponent extends BaseFilterComponent<EtqFilterModel> implements OnInit {

    //Injections
    private readonly alertCtrl = this.injector.get<BvAlertController>(BvAlertController);

    // Input/Output
    @Input()
	public documentType?: EtqDocumentTypeEnum;
    @Output() public search = new EventEmitter();
	

    constructor(protected readonly injector: Injector) {
        super(injector, new EtqFilterModel(), 'EtqFilterComponent');
    }
    
    public model = new EtqFilterModel();

    public readonly NONEVALUES = {
        STRING: 'NONE',
        NUMBER: -9999
    };

    ngOnInit(): void {
        if (!this.documentType) throw new Error('Document type is not defined');
    }

    public onClickSearch(): void {
        this.logger.debug('onClickSearch', this.model);
        // If no filter defined
        if (this.filterIsEmpty()) {
            this.alertCtrl
                .create({
                    header: this.translateSrv.instant('common.filter.nofilters.alert.title'),
                    message: this.translateSrv.instant('common.filter.nofilters.alert.message'),
                    buttons: [
                        {
                            text: this.translateSrv.instant('common.filter.nofilters.alert.button'),
                            cssClass: 'primary'
                        }
                    ]
                });
        } else {
            this.search.emit(this.model);
        }
    }

    /*
    private filterIsEmpty(): boolean {
        return (StringUtils.isBlank(this.model.auditRef));
    }
    */
}
