import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppSharedModule } from 'src/app/app-shared.module';
import { CdmFilterComponent } from './cdm-filter/cdm-filter.component';
import { CdmMetadataComponent } from './cdm-metadata/cdm-metadata.component';
import { CdmProjectComponent } from './cdm-project/cdm-project.component';
import { CdmProposalComponent } from './cdm-proposal/cdm-proposal.component';

@NgModule({
	declarations: [
		CdmFilterComponent,
		CdmMetadataComponent,
		CdmProjectComponent,
		CdmProposalComponent
	],
	imports: [
		AppSharedModule,
		IonicModule.forRoot()
	],
	exports: [
		CdmProjectComponent,
		CdmProposalComponent
	]
})
export class CdmComponentsModule { }
