import { BaseMetadataComponent } from "../../base-metadata-component";
import { Component, Injector, Inject, LOCALE_ID } from "@angular/core";
import { FieldDefinitions } from "../../../models/field-definition";
import { Observable } from "rxjs";
import { ModalController } from "@ionic/angular";
import { AwiConstants } from "../../../constants/awi";
import { AwiDocumentModel } from "../../../models/awi/awi-awidoc-document.model";
import { AwiService } from "../../../providers/services/business/awi.service";

@Component({
	selector: "page-awi-metadata",
	templateUrl: "awi-metadata.component.html"
})
export class AwiMetadataComponent extends BaseMetadataComponent {
	constructor(
		protected injector: Injector,
		@Inject(LOCALE_ID) public locale: string
	) {
		super(injector, "AwiMetadataComponent");
	}

	//injections
	private readonly viewCtrl =
		this.injector.get<ModalController>(ModalController);
	private readonly awiSrv = this.injector.get<AwiService>(AwiService);

	// Public
	public model?: AwiDocumentModel;
	public showMore = false;

	protected getFields(): FieldDefinitions {
		return AwiConstants.FIELDS_AWI;
	}

	protected init(): void {}

	public onClickClose(): void {
		this.viewCtrl.dismiss();
	}

	public onClickLink(): void {
		if (!this.model) {
			throw new Error("Download could not be done: no model");
		}
		let obs: Observable<string> | undefined = undefined;
		obs = this.awiSrv.download(this.model);
		if (obs) {
			obs.subscribe((response: string) => {
				this.logger.debug("Download url", response);
				console.log(response);
				window.open(JSON.parse(JSON.stringify(response)).url);
			});
		}
	}
}
