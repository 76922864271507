import { ChangeDetectorRef, Pipe } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'trl',
	pure: false
})
export class TranslationPipe extends TranslatePipe {

	constructor(changeDetectorRef: ChangeDetectorRef, translateSrv: TranslateService) {
		super(translateSrv, changeDetectorRef);
	}

	updateValue(key: string, interpolateParams?: Object, translations?: any): void {
		super.updateValue(key ? key.toLowerCase() : key, interpolateParams, translations);
	}

	transform(text: string, ...args: any[]) {
		return super.transform(text ? text.toLowerCase() : text, ...args);
	}
}
