import { Component, Inject, Injector, LOCALE_ID } from '@angular/core';
import { CdmConstants } from '../../../constants/cdm';
import { ApiResult } from '../../../models/api-result';
import { CdmDocumentTypeEnum } from '../../../models/cdm/cdm-document-type.enum';
import { FieldDefinitions } from '../../../models/field-definition';
import { CdmService } from '../../../providers/services/business/cdm.service';
import { BasePageComponent } from '../../base-page-component';
import { CdmFilterModel } from '../cdm-filter/cdm-filter.component';

@Component({
	selector: 'page-cdm-proposal',
	templateUrl: 'cdm-proposal.component.html'
})
export class CdmProposalComponent extends BasePageComponent {

	// Injections
	private readonly cdmService = this.injector.get<CdmService>(CdmService);

	// Public
	public type = CdmDocumentTypeEnum.PROPOSAL;

	constructor(protected readonly injector: Injector, @Inject(LOCALE_ID) public locale: string) {
		super(injector, locale, 'CdmProposalComponent');
	}

	protected load(filter: CdmFilterModel): void {
		this.logger.debug('load', filter);
		this.loading();
		// Load data
		this.cdmService
			.getAll(CdmDocumentTypeEnum.PROPOSAL, filter)
			.subscribe(
				(result: ApiResult<any>) => {
					this.apiResult = result;
					this.setLines(this.cdmService.apiResultToModels(result));
					// Stop loading
					this.loading(false);
				}
			);
	}

	protected getFields(): FieldDefinitions {
		return CdmConstants.FIELDS_PROPOSAL;
	}

}
