import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AppSharedModule } from 'src/app/app-shared.module';
import { ShirleyFilterComponent } from './shirley-filter/shirley-filter.component';
import { ShirleyJobComponent } from './shirley-job/shirley-job.component';
import { ShirleyMetadataComponent } from './shirley-metadata/shirley-metadata.component';

@NgModule({
	declarations: [
		ShirleyFilterComponent,
		ShirleyJobComponent,
		ShirleyMetadataComponent
	],
	imports: [
		FormsModule,
		AppSharedModule,
		IonicModule,
		IonicModule.forRoot(),
	],
	exports: [
		ShirleyFilterComponent,
		ShirleyJobComponent,
		ShirleyMetadataComponent
	]
})
export class ShirleyComponentsModule { }
