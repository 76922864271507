import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, LOCALE_ID } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { ShirleyJobService } from 'src/app/providers/services/business/shirley-job.service';
import { ShirleyConstants } from '../../../constants/shirley';
import { WebServices } from '../../../constants/webservices';
import { FieldDefinitions } from '../../../models/field-definition';
import { ShirleyDocumentModel } from '../../../models/shirley/shirley-document.model';
import { Config } from '../../../providers/core/config/config';
import { UrlUtils } from '../../../utils/url-utils';
import { BaseMetadataComponent } from '../../base-metadata-component';

@Component({
	selector: 'page-shirley-metadata',
	templateUrl: 'shirley-metadata.component.html'
})
export class ShirleyMetadataComponent extends BaseMetadataComponent {

	// Public
	public translations = new Map<string, string>();
	public downloadUrl?: string;
	// Injections
	private readonly viewCtrl = this.injector.get<ModalController>(ModalController);
	private readonly config = this.injector.get<Config>(Config);
	private readonly shirleySrv = this.injector.get<ShirleyJobService>(ShirleyJobService);

	protected getFields(): FieldDefinitions {
		return ShirleyConstants.FIELDS_JOB;
	}
	protected init(): void {
		const url = WebServices.parametrize(WebServices.SHIRLEY_JOB.GET_DOWNLOAD,this.model).url;
		this.downloadUrl = UrlUtils.concat(this.config.get(Constants.CONFIG.API.PATH), url);
	}


	// Public
	public model?: ShirleyDocumentModel;
	public showMore = false;

	// Private
	private readonly datePipe = new DatePipe(this.locale);

	constructor(protected readonly injector: Injector, @Inject(LOCALE_ID) public locale: string) {
		super(injector, 'ShirleyMetadataPage');
	}

	public onClickClose(): void {
		this.viewCtrl.dismiss();
	}

	public onClickLink(): void {
		if (!this.model) {
			throw new Error('Download could not be done: no model');
		}
		let obs: Observable<string> | undefined = undefined;
		obs = this.shirleySrv.download(this.model);
		if (obs) {
			obs.subscribe((response: string) => {
				this.logger.debug('Download url', response);
				console.log(response);
				window.open(JSON.parse(JSON.stringify(response)).url);
			});
		}
	}
}
