import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AppSharedModule } from 'src/app/app-shared.module';
import { Sequence6FilterComponent } from './sequence6-filter/sequence6-filter.component';
import { Sequence6JobComponent } from './sequence6-job/sequence6-job.component';
import { Sequence6MetadataComponent } from './sequence6-metadata/sequence6-metadata.component';

@NgModule({
    declarations: [
        Sequence6FilterComponent,
        Sequence6MetadataComponent,
        Sequence6JobComponent
    ],
    imports: [
        FormsModule,
        AppSharedModule,
        IonicModule
    ],
    entryComponents: [
    ],
    exports: [
        Sequence6FilterComponent,
        Sequence6MetadataComponent,
        Sequence6JobComponent
    ]
})
export class Sequence6ComponentsModule { }
