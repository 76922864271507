import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AppController {

	private readonly backToLogin$ = new Subject<void>();
	private readonly showHeader$ = new Subject<boolean>();

	public getBackToLogin(): Observable<void> {
		return this.backToLogin$;
	}

	public backToLogin(): void {
		this.backToLogin$.next();
		this.showHeader(false);
	}

	public onShowHeader(): Observable<boolean> {
		return this.showHeader$;
	}

	public showHeader(show: boolean): void {
		this.showHeader$.next(show);
	}
}
