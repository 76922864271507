import {
	Component,
	EventEmitter,
	Injector,
	OnInit,
	Output,
} from "@angular/core";
import { BvAlertController } from "@bv/ionic";
import { Observable } from "rxjs";
import { AuditorAssessmentConstants } from "../../../constants/auditor-assessment";
import { AuditorAssessmentService } from "../../../providers/services/business/auditor-assessment.service";
import { BaseFilterComponent } from "../../base-filter-component";

export class AuditorAssessmentFilterModel {
	public lastName?: string;
	public referential?: string;
	public auditorStatus?: string;
	public activityCode?: string;
	public manager?: string;
	public status?: string;
	public country?: string;
	public isLastVersion?: string;
}

@Component({
	selector: "app-aa-filter",
	templateUrl: "aa-filter.component.html"
})
export class AuditorAssessmentFilterComponent
	extends BaseFilterComponent<AuditorAssessmentFilterModel>
	implements OnInit
{
	// Injections
	private readonly aaSrv = this.injector.get<AuditorAssessmentService>(
		AuditorAssessmentService
	);
	private readonly alertCtrl =
		this.injector.get<BvAlertController>(BvAlertController);

	@Output()
	public search = new EventEmitter();

	public referentials?: Observable<(number | string)[]>;
	public auditorStatuss?: Observable<(number | string)[]>;
	public statuss?: Observable<(number | string)[]>;
	public countries?: Observable<(number | string)[]>;
	public lastVersions?: Observable<(number | string)[]>;

	constructor(protected readonly injector: Injector) {
		super(
			injector,
			new AuditorAssessmentFilterModel(),
			"AuditorAssessmentFilterComponent"
		);
		this.model.referential = this.NONEVALUES.STRING;
		this.model.auditorStatus = this.NONEVALUES.STRING;
		this.model.status = this.NONEVALUES.STRING;
		this.model.country = this.NONEVALUES.STRING;
		this.model.isLastVersion = this.NONEVALUES.STRING;
	}

	ngOnInit(): void {
		this.referentials = this.aaSrv.getFilterData(
			AuditorAssessmentConstants.FIELDS_ASSESSMENT.REFERENTIAL.name,
			this.NONEVALUES.STRING
		);
		this.auditorStatuss = this.aaSrv.getFilterData(
			AuditorAssessmentConstants.FIELDS_ASSESSMENT.AUDITOR_STATUS.name,
			this.NONEVALUES.STRING
		);
		this.statuss = this.aaSrv.getFilterData(
			AuditorAssessmentConstants.FIELDS_ASSESSMENT.STATUS.name,
			this.NONEVALUES.STRING
		);
		this.lastVersions = this.aaSrv.getFilterData(
			AuditorAssessmentConstants.FIELDS_ASSESSMENT.IS_LAST_VERSION.name,
			this.NONEVALUES.STRING
		);
		this.countries = this.aaSrv.getFilterData(
			AuditorAssessmentConstants.FIELDS_ASSESSMENT.COUNTRY.name,
			this.NONEVALUES.STRING
		);
	}

	public onClickSearch(): void {
		// Update none values
		this.logger.debug("onClickSearch", this.model);
		this.prepareDropdownStringValue(
			"referential",
			"auditorStatus",
			"status",
			"isLastVersion",
			"country"
		);
		// If no filter defined
		if (this.filterIsEmpty()) {
			this.logger.debug("model", this.model);
			/*this.alertCtrl
				.create({
					header: this.translateSrv.instant('common.filter.nofilters.alert.title'),
					message: this.translateSrv.instant('common.filter.nofilters.alert.message'),
					buttons: [
						{
							text: this.translateSrv.instant('common.filter.nofilters.alert.button'),
							cssClass: 'primary'
						}
					]
				}).present();*/
			// FIXME : How fix "present()" ?
			this.alertCtrl.create({
				header: this.translateSrv.instant(
					"common.filter.nofilters.alert.title"
				),
				message: this.translateSrv.instant(
					"common.filter.nofilters.alert.message"
				),
				buttons: [
					{
						text: this.translateSrv.instant(
							"common.filter.nofilters.alert.button"
						),
						cssClass: "primary",
					},
				],
			});
		} else {
			this.search.emit(this.model);
		}
	}
}
