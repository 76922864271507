import { Injector } from '@angular/core';
import { BvBase } from '@bv/angular-core';
import { Logger, LoggerFactory } from '@bv/angular-logger';
import { Config } from '../core/config/config';

export abstract class BaseService extends BvBase{

	private internallogger: Logger | undefined;

	constructor(protected readonly injector: Injector, protected readonly loggerName = 'BaseService') {
		super(injector, loggerName);
	 }

	protected get logger(): Logger {
		if (!this.internallogger) {
			// If config not loaded
			if (!this.injector.get(Config) || !this.injector.get(Config).getAll()) {
				// Return tmp logger
				return this.injector.get(LoggerFactory).buildLogger(this.loggerName);
			} else {
				this.internallogger = this.injector.get(LoggerFactory).buildLogger(this.loggerName);
			}
		}
		if (this.internallogger) {
			return this.internallogger;
		}
		return this.injector.get(LoggerFactory).buildLogger(this.loggerName);
	}

}
