import { Component, Injector } from '@angular/core';
import { AwiFilterModel } from '../awi-filter/awi-filter.component';
import { FieldDefinitions } from '../../../models/field-definition';
import { BasePageComponent } from '../../base-page-component';
import { LoaderUtils } from '@bv/ionic';
import { ApiResult } from '../../../models/api-result';
import { AwiConstants } from '../../../constants/awi';
import { AwiDocumentModel } from '../../../models/awi/awi-awidoc-document.model';
import { AwiService } from '../../../providers/services/business/awi.service';

@Component({
    selector: 'page-awi-awidoc',
    templateUrl: 'awi-awidoc.component.html'
  })
  export class AwiDocComponent extends BasePageComponent {
    
    constructor(protected injector: Injector) {
      super(injector, 'AwiDocComponent');
    }

    //injections
    public readonly awiSrv = this.injector.get<AwiService>(AwiService);

    protected load(filter: AwiFilterModel): void {
      // Start loading
      LoaderUtils.presentLoading(this.injector).then(loading => {
        // Load data
        this.awiSrv.getAll(filter)
          .subscribe(
            (result: ApiResult<AwiDocumentModel>) => {
              this.apiResult = result;
              this.lines = this.awiSrv.apiResultToModels(result);
              // Stop loading
              loading.dismiss();
            },
            (error: any) => {
              this.logger.error('Error during loading', error);
              // Stop loading
              loading.dismiss();
            }
          );
      });
    }

    protected getFields(): FieldDefinitions {
      return AwiConstants.FIELDS_AWI;
    }
}