import { Injector } from '@angular/core';
import { ObjectUtils, StringUtils } from '@bv/angular-commons';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from './base-component';

export abstract class BaseFilterComponent<T> extends BaseComponent {

	// Injections
	protected readonly translateSrv = this.injector.get<TranslateService>(TranslateService);

	public readonly NONEVALUES = {
		STRING: 'NONE',
		NUMBER: -9999
	};

	constructor(
		protected readonly injector: Injector,
		public model: T,
		protected readonly loggerName = 'BaseFilterComponent'
	) {
		super(injector, loggerName);
	}

	protected filterIsEmpty(): boolean {
		return Object.keys((this.model as any))
			// Keep  not empy values
			.filter(key => {
				const value = ObjectUtils.readProperty(this.model, key);
				return StringUtils.isString(value)
					? StringUtils.isNotBlank(value)
					: (value !== undefined && value !== null);
			})
			.length === 0;
	}

	public prepareDropdownStringValue(...fields: string[]): any {
		(fields || []).forEach(field => {
			const value = ObjectUtils.readProperty(this.model, field);
			if (value === this.NONEVALUES.STRING) {
				ObjectUtils.writeProperty(this.model, field, undefined);
			}
		});
	}

	public getDatepickerOptions(fieldModel: string): any {
		return {
			buttons: [{
				text: this.translateSrv.instant('common.button.clear'),
				handler: () => ObjectUtils.writeProperty(this.model, fieldModel, '')
			}]
		};
	}

}
