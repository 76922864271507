import { Injectable, Injector } from "@angular/core";
import { map, Observable } from "rxjs";
import { CdmFilterModel } from "../../../components/cdm/cdm-filter/cdm-filter.component";
import { WebServices } from "../../../constants/webservices";
import { ApiResult } from "../../../models/api-result";
import { CdmDocumentTypeEnum } from "../../../models/cdm/cdm-document-type.enum";
import { HttpService } from "../../core/http/http.service";
import { BaseService } from "../base-service";
import { ApiService } from "../tech/api.service";

@Injectable()
export class CdmService extends BaseService {
	// Injections
	private readonly httpSrv = this.injector.get<HttpService>(HttpService);
	private readonly apiSrv = this.injector.get<ApiService>(ApiService);

	constructor(protected readonly injector: Injector) {
		super(injector, "CdmService");
	}

	public getFilterData(
		type: CdmDocumentTypeEnum,
		field: string,
		nonevalue?: string | number
	): Observable<(string | number)[]> {
		const req =
			type === CdmDocumentTypeEnum.PROJECT
				? WebServices.parametrize(WebServices.CDM_PROJECT.GET_FILTERDATA, field)
				: WebServices.parametrize(WebServices.CDM_PROPOSAL.GET_FILTERDATA, field);
		return this.httpSrv.call(req).pipe(
			// Extract data
			map((res: ApiResult<any>) => this.apiSrv.extractAggregation(res)),
			// Add none value
			map((res: (string | number)[]) =>
				nonevalue !== undefined ? [nonevalue, ...res] : res
			)
		);
	}

	public getAll(
		type: CdmDocumentTypeEnum,
		filters: CdmFilterModel
	): Observable<ApiResult<any>> {
		const req =
			type === CdmDocumentTypeEnum.PROJECT
				? WebServices.parametrize(WebServices.CDM_PROJECT.GET_ALL, filters)
				: WebServices.parametrize(WebServices.CDM_PROPOSAL.GET_ALL, filters);
		return this.httpSrv.call(req);
	}

	public apiResultToModels(res: ApiResult<any>): any[] {
		return res.hits.hits.map((hit) =>
			Object.assign({ _id: hit._id }, hit._source)
		);
	}

	public download(type: CdmDocumentTypeEnum, doc: any): Observable<string> {
		const req =
			type === CdmDocumentTypeEnum.PROJECT
				? WebServices.parametrize(WebServices.CDM_PROJECT.GET_DOWNLOAD, doc)
				: WebServices.parametrize(WebServices.CDM_PROPOSAL.GET_DOWNLOAD, doc);
		return this.httpSrv.call(req);
	}

	public getAutocompleteData(
		field: string,
		value: any,
		type: CdmDocumentTypeEnum
	): Observable<(string | number)[]> {
		const req =
			type === CdmDocumentTypeEnum.PROJECT
				? WebServices.parametrize(WebServices.CDM_PROJECT.GET_AUTOCOMPLETEDATA, field, value)
				: WebServices.parametrize(WebServices.CDM_PROPOSAL.GET_AUTOCOMPLETEDATA, field, value);
		return this.httpSrv
			.call(req)
			.pipe(
				map((res: ApiResult<any>) =>
					this.apiSrv.extractAggregation(res)
				)
			);
	}
}
