import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ObjectUtils, StringUtils } from '@bv/angular-commons';
import { LoggerFactory } from '@bv/angular-logger';
import { Observable, of } from 'rxjs';
import { CdmConstants } from '../../../../constants/cdm';
import { WebServices } from '../../../../constants/webservices';

declare var window: Window;

@Injectable()
export class HttpRequestMockInterceptor implements HttpInterceptor {

	private readonly logger = this.injector.get<LoggerFactory>(LoggerFactory).buildLogger('HttpRequestMockInterceptor');

	constructor(private readonly injector: Injector) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		try {
			if (ObjectUtils.readProperty(window, 'mock') !== true) {
				return next.handle(request);
			}
		} catch {
			return next.handle(request);
		}
		this.logger.warn('url', request.url);
		if (StringUtils.contains(request.url, WebServices.CDM_PROJECT.GET_FILTERDATA.url)) {
			return this.requestCdmjFilterdata(request.url);
		}
		return next.handle(request);
	}

	private requestCdmjFilterdata(url: string): Observable<HttpEvent<any>> {
		const datas: (string | number)[] = [];
		if (StringUtils.contains(url, CdmConstants.FIELDS_PROJECT.SERVICE_TYPE.name)) {
			datas.push('service type 1');
			datas.push('service type 2');
			datas.push('service type 3');
		}
		return this.responseFilterData(...datas);
	}

	private responseFilterData(...datas: (string | number)[]): Observable<HttpEvent<any>> {
		const buckets: { key: string | number, doc_count: number }[] = [];
		datas.forEach((key: string | number) => buckets.push({ key, doc_count: 0 }));
		const data = {
			aggregations: {
				uniq_field: {
					buckets,
					doc_count_error_upper_bound: 0,
					sum_other_doc_count: 0
				}
			}
		};
		return this.successResponse(data);
	}

	private successResponse(data: any): Observable<HttpEvent<any>> {
		this.logger.warn('mock data', data);
		return of(new HttpResponse({ status: 200, body: data, headers: this.getHeaders() }));
	}

	private getHeaders(): HttpHeaders {
		return new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
	}
}
