import { HttpClient } from "@angular/common/http";
import { AppWindow } from "src/app/models/window";
import { Config } from "./config";

declare var window: AppWindow;

export function AppConfigFactory(config: Config, http: HttpClient): Function {
	console.debug("AppConfigFactory - load config");
	const url =
		(window.platform === "browser" ? "../" : "./assets/") + "config.json";
	return async () => {
		return new Promise((resolve, reject) => {
			http.get(url).subscribe({
				next: (configData: any) => {
					config.set(configData);
					console.debug(
						"AppConfigFactory - config loaded",
						configData
					);
					resolve("AppConfigFactory - config loaded");
				},
				error: (err: any) => {
					reject(err);
				},
			});
		});
	};
}
