import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AwiFilterComponent } from './awi-filter/awi-filter.component';
import { AwiDocComponent } from './awi-awidoc/awi-awidoc.component';
import { AwiMetadataComponent } from './awi-metadata/awi-metadata.component';
import { AppSharedModule } from 'src/app/app-shared.module';

@NgModule({
    declarations: [
        AwiFilterComponent,
        AwiMetadataComponent,
        AwiDocComponent
    ],
    imports: [
        AppSharedModule,
        IonicModule
    ],
    entryComponents: [
    ],
    exports: [
        AwiFilterComponent,
        AwiMetadataComponent,
        AwiDocComponent
    ]
})
export class AwiComponentsModule { }
