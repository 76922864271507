import { Injectable, Injector } from '@angular/core';
import { BvHttpRequestDescriptor, BvHttpService } from '@bv/angular-core';
import { catchError, map, Observable, share, throwError } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { ApiResult } from '../../../models/api-result';
import { BaseService } from '../../services/base-service';
import { Config } from '../config/config';

@Injectable({ providedIn: 'root' })
export class HttpService extends BaseService {

	// Injections
	readonly #httpSrv = this.injector.get(BvHttpService);
	private readonly config = this.injector.get(Config);

	constructor(readonly injector: Injector) {
		super(injector, 'HttpService');
	}
	
	/**
	 * Do a WS call.
	 *
	 * @param {HttpRequest} req the main parameters of request
	 * @param {any} bodyOrParams the body or params (only for GET) of the request (optionnal)
	 * @returns {Observable<any>}
	 * @memberof HttpService
	 */
	public call(req: BvHttpRequestDescriptor): Observable<any> {
		const finalUrl = this.url(req.url);
		let obs;
		switch (req.method) {
			case 'GET':
			case 'DELETE':
			case 'POST':
			case 'PUT':
				req.url = finalUrl;
				obs = this.#httpSrv.call(req);
				break;
			// Others methods (to implements if needed)
			default:
				throw new Error(`RequestMethod ${req.method} not supported in HttpService`);
		}
		
		return obs.pipe(
			catchError(this.errorHandler.bind(this)),
			map(this.resultHandler.bind(this)),
			share()
		)
	}

	/**
	 * Handle error.
	 *
	 * @private
	 * @param {*} err
	 * @returns {Observable<any>}
	 * @memberof HttpOService
	 */
	private errorHandler(err: ApiResult<any>): Observable<never> {
		this.logger.error('Error during Http call', err);
		return throwError(() => {
			return err;
		})
	}

	/**
	 * Handle results.
	 *
	 * @private
	 * @param {*} res
	 * @returns {*}
	 * @memberof HttpOService
	 */
	private resultHandler(res: ApiResult<any>): any {
		this.logger.debug('response', res);
		if (res.error) {
			this.logger.error('Error during Http call', res.error);
			return throwError(() => {
				return new Error(res.error.reason);
			})
		}
		return res;
	}

	/**
	 * Format the URL.
	 *
	 * @private
	 * @param {string} relative
	 * @returns {string}
	 * @memberof HttpService
	 */
	private url(relative: string): string {
		if (relative.startsWith('http')) {
			return relative;
		}
		let url = this.config.get(Constants.CONFIG.API.PATH);
		if (!url.endsWith('/')) {
			url += '/';
		}
		return url + relative;
	}
}
