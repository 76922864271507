import { FieldDefinition } from '../models/field-definition';

export abstract class AwiConstants {

    public static readonly FIELDS = {
        PROPOSAL_NUM: 'proposal_num',
        ISSUING_YEAR: 'proposal_issuing_year',
        BUSINESS_UNIT: 'proposal_bu',
        CLIENT_ZIG_NUM: 'client_zig_number',
        ZIG_CONTRACT_NUM: 'proposal_zig_contract_num'
    };

    public static readonly FIELDS_AWI: {
        [key: string]: FieldDefinition
    } = {
        ORG_LEVEL_2: { name: 'organization_level_2', detailMain: true},
        PROPOSAL_NUM: { name: 'proposal_num', detailMain: true, columnIndex: 1 },
        CREATION_DATE: { name: 'proposal_creation_date', detailMain: true, dateFormat: 'dd/MM/yyyy' },
        ORG_LEVEL_3: { name: 'organization_level_3', detailMain: true },
        AWI_PROPOSAL_ID: { name: 'awi_proposal_id', detailMain: true },
        ISSUING_DATE: { name: 'proposal_issuing_date', dateFormat: 'dd/MM/yyyy', detailMain: true, columnIndex: 2 },
        OWNER_COUNTRY: { name: 'proposal_owner_country', detailMain: true, columnIndex: 6 },
        GROUP_STATUS: { name: 'proposal_group_status', detailMain: true },
        LAST_MODIFICATION_DATE: { name: 'proposal_last_modification_date', detailMain: true, dateFormat: 'dd/MM/yyyy' },
        BUSINESS_UNIT: { name: 'proposal_bu', detailMain: true, columnIndex: 3 },
        CLIENT_NAME: { name: 'client_name', detailMain: true },
        MASTER_PROPOSAL_NUMBER: { name: 'master_proposal_number', detailMain: true },
        AWI_GP_REPORT_LINE: { name: 'proposal_awi_gp-report_line', detailMain: true },
        CLIENT_REF: { name: 'proposal_client_ref', detailMain: true },
        TECH_REVIEW_STATUS: { name: 'tech_review_status', detailMain: true },
        DESCRIPTION: { name: 'proposal_description', detailMain: true },
        CLIENT_ZIG_NUMBER: { name: 'client_zig_number', detailMain: true, columnIndex: 4 },
        REVIEWER_NAME: { name: 'reviewer_name', detailMain: true },
        GROUP_PRODUCT: { name: 'proposal_group_product', detailMain: true },
        ZIG_CONTRACT_NUM: { name: 'proposal_zig_contract_num', detailMain: true, columnIndex: 5 },
        REVIEW_DATE: { name: 'review_date', detailMain: true, dateFormat: 'dd/MM/yyyy' },
        LOCAL_PRODUCT: { name: 'proposal_local_product', detailMain: true },
        OLD_ZIG_CONTRACT: { name: 'old_zig_contract', detailMain: true },
        CLIENT_NAME2: { name: 'client_name2', detailMain: true },
        FILENAME: { name: 'filename', detailMain: true, columnIndex: 7 }
    };
}
