import { Component } from '@angular/core';

@Component({
	selector: 'page-template',
	templateUrl: 'page-template.component.html',
	styleUrls: ['page-template.component.scss']
})
export class PageTemplateComponent {

}
