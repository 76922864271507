import { Component, Injector, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Constants } from 'src/app/app.constants';
import { EtqConstants } from '../../../constants/etq';
import { WebServices } from '../../../constants/webservices';
import { EtqDocumentTypeEnum } from '../../../models/etq/etq-document-type.enum';
import { FieldDefinitions } from '../../../models/field-definition';
import { Config } from '../../../providers/core/config/config';
import { EtqService } from '../../../providers/services/business/etq.service';
import { UrlUtils } from '../../../utils/url-utils';
import { BaseMetadataComponent } from '../../base-metadata-component';


@Component({
	selector: 'page-etq-metadata',
	templateUrl: 'etq-metadata.component.html'
})
export class EtqMetadataComponent extends BaseMetadataComponent {

	// Injections
	private readonly viewCtrl = this.injector.get<ModalController>(ModalController);
	private readonly config = this.injector.get<Config>(Config);
	private readonly etqSrv = this.injector.get<EtqService>(EtqService);

	// Public
	@Input()
	public type?: EtqDocumentTypeEnum;

	// Public
	public translations = new Map<string, string>();
	public downloadUrl?: string;

	constructor(protected readonly injector: Injector) {
		super(injector, 'EtqMetadataComponent');
	}

	protected init(): void {
		const req = EtqDocumentTypeEnum.AUDIT
			? WebServices.parametrize(WebServices.ETQ_AUDIT.GET_DOWNLOAD, this.model)
			: EtqDocumentTypeEnum.INCIDENT ? WebServices.parametrize(WebServices.ETQ_INCIDENT.GET_DOWNLOAD, this.model) : WebServices.parametrize(WebServices.ETQ_COMPLAINT.GET_DOWNLOAD, this.model);
		const url = req.url;
		this.downloadUrl = UrlUtils.concat(this.config.get(Constants.CONFIG.API.PATH), url);
	}

	public onClickClose(): void {
		this.viewCtrl.dismiss();
	}

	public onClickLink(): void {
		if (!this.type) {
			throw new Error('Type not defined');
		}
		this.etqSrv
			.download(this.type, this.model)
			.subscribe((response: string) => {
				this.logger.debug('Download url', response);
				console.log(response);
				window.open(JSON.parse(JSON.stringify(response)).url);
			});
	}

	protected getFields(): FieldDefinitions {
		return EtqDocumentTypeEnum.AUDIT === this.type
			? EtqConstants.FIELDS_AUDIT
			: EtqDocumentTypeEnum.INCIDENT === this.type
				? EtqConstants.FIELDS_INCIDENT
				: EtqDocumentTypeEnum.COMPLAINT === this.type
					? EtqConstants.FIELDS_COMPLAINT
					: {};
	}

}
