import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { StringUtils } from '@bv/angular-commons';
import { BaseComponent } from '../../base-component';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { ZigService } from '../../../providers/services/business/zig.service';
import { ZigConstants } from '../../../constants/zig';
import { BvAlertController } from '@bv/ionic';

export class ZigFilterModel {
    invoiceNumber_from: string | undefined = undefined;
    invoiceNumber_until: string | undefined = undefined;
    documentType: string | undefined = undefined;
    companyNumber: string | undefined = undefined;
    clientNumber: string | undefined = undefined;
    businessNumber: string | undefined = undefined;
    invoicingDate_from: string | undefined = undefined;
	invoicingDate_until: string | undefined = undefined;
    invoicingDate: string | undefined = undefined;
}

@Component({
    selector: 'app-zig-filter',
    templateUrl: 'zig-filter.component.html'
})
export class ZigFilterComponent extends BaseComponent implements OnInit {

    //Injections
    private readonly alertCtrl = this.injector.get<BvAlertController>(BvAlertController);
    public readonly zigSrv = this.injector.get<ZigService>(ZigService);
    private readonly translateSrv = this.injector.get<TranslateService>(TranslateService);

    // Input/Output
    @Output() public search = new EventEmitter();

    constructor(protected readonly injector: Injector) {
        super(injector, 'ZigFilterModel');
    }

    public model = new ZigFilterModel();
    public documentTypes: Observable<String[]> | undefined = undefined;

    public readonly NONEVALUES = {
        STRING: 'NONE',
        NUMBER: -9999
    };

    ngOnInit(): void {
        const documentTypes = this.zigSrv.getFilterData(ZigConstants.FIELDS.DOCUMENT_TYPE);
        this.init(documentTypes);
        this.model.documentType = this.NONEVALUES.STRING;
    }

    private init(documentTypes: Observable<any[]>): void {
        this.documentTypes = documentTypes.pipe(map((arr: string[]) => [this.NONEVALUES.STRING, ...arr]));
    }

    public onChange(event: string, field: string): void {
        if (event === this.NONEVALUES.STRING) {
            this.logger.debug('onChange' + field, event);
            (this.model as any)[field] = undefined;
        }
    }

    public onClickSearch(): void {
        // Update none values
        this.model.documentType = this.model.documentType === this.NONEVALUES.STRING ? undefined : this.model.documentType;
        this.logger.debug('onClickSearch', this.model);
        // If no filter defined
        if (this.filterIsEmpty()) {
            this.alertCtrl
                .create({
                    header: this.translateSrv.instant('common.filter.nofilters.alert.title'),
                    message: this.translateSrv.instant('common.filter.nofilters.alert.message'),
                    buttons: [
                        {
                            text: this.translateSrv.instant('common.filter.nofilters.alert.button'),
                            cssClass: 'primary'
                        }
                    ]
                });
        } else {
            this.search.emit(this.model);
        }
    }

    private filterIsEmpty(): boolean {
        return (StringUtils.isBlank(this.model.invoiceNumber_from)
            && (StringUtils.isBlank(this.model.documentType) || this.model.documentType === this.NONEVALUES.STRING)
            && StringUtils.isBlank(this.model.companyNumber)
            && StringUtils.isBlank(this.model.clientNumber)
            && StringUtils.isBlank(this.model.businessNumber)
            && StringUtils.isBlank(this.model.invoicingDate_from) 
            && StringUtils.isBlank(this.model.invoicingDate_until));
    }
}
