import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BvLogModule } from "@bv/angular-logger";
import { BvPlatformService } from "@bv/ionic";
import { IonicModule, Platform } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { AppConfigFactory } from "./providers/core/config/app-config-factory";
import { Config } from "./providers/core/config/config";
import { CustomErrorHandler } from "./providers/core/custom-error-handler";
import { HttpRequestHeadersInterceptor } from "./providers/core/http/interceptors/http-request-headers-interceptor";
import { HttpRequestMockInterceptor } from "./providers/core/http/interceptors/http-request-mock-interceptor";
import { AuthentificationService } from "./providers/services/tech/auth.service";

import { AppRoutingModule } from "./app-routing.module";
import { AppSharedModule } from "./app-shared.module";
import { MyApp } from "./app.component";
import { LoginPageModule } from "./page/login/login.module";
import { CustomTranslateLoader } from "./providers/core/custom-translate-loader";
import { ShirleyComponentsModule } from "./components/shirley/shirley-components.module";
import { Sequence6ComponentsModule } from "./components/sequence6/sequence6-components.module";
import { CdmComponentsModule } from "./components/cdm/cdm-components.module";
import { AwiComponentsModule } from "./components/awi/awi-components.module";
import { AuditorAssessmentComponentsModule } from "./components/auditor-assessment/aa-components.module";
import { EtqComponentsModule } from "./components/etq/etq-components.module";
import { ZigComponentsModule } from "./components/zig/zig-components.module";
import { BvCoreModule } from "@bv/angular-core";
import { HomePageModule } from "./page/home/home.module";

export function HttpLoaderFactory(http: HttpClient, injector: Injector) {
	return new CustomTranslateLoader(http, injector);
}

@NgModule({
	declarations: [MyApp],
	imports: [
		// Share
		AppSharedModule,
		// Angular
		BrowserModule,
		HttpClientModule,
		// Bv
		BvCoreModule.forRoot(),
		BvLogModule.forRoot(),
		// Misc
		TranslateModule.forRoot({
			loader: {
				deps: [HttpClient, Injector],
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory
			}
		}),
       	// Ionic
		IonicModule.forRoot(),

		// Pages
		AppRoutingModule,
		HomePageModule,

		// !!!! TO IMPORT !!!!
		ShirleyComponentsModule,
		Sequence6ComponentsModule,
		CdmComponentsModule,
		AwiComponentsModule,
		AuditorAssessmentComponentsModule,
		EtqComponentsModule,
		ZigComponentsModule
    ],
	providers: [
        // App
		{
			provide: ErrorHandler,
			useClass: CustomErrorHandler,
			deps: [Injector],
		},
        {
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestHeadersInterceptor,
			deps: [AuthentificationService, Config],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestMockInterceptor,
			multi: true
		},
		// initialize application
		{
			deps: [Config, HttpClient, BvPlatformService, Platform],
			multi: true,
			provide: APP_INITIALIZER,
			useFactory: AppConfigFactory,
		}
    ],
	bootstrap: [MyApp]

})
export class AppModule {}
