import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompletePanelComponent } from './autocomplete-panel/autocomplete-panel.component';
import { HeaderComponent } from './header/header.component';
import { PageTemplateComponent } from './page-template/page-template.component';
import { TooManyResultsComponent } from './too-many-results/too-many-results.component';

@NgModule({
	declarations: [
		// Components
		HeaderComponent,
		PageTemplateComponent,
		AutocompletePanelComponent,
		TooManyResultsComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		TranslateModule.forChild()
	],
	exports: [
		IonicModule,
		HeaderComponent,
		PageTemplateComponent,
		AutocompletePanelComponent,
		TooManyResultsComponent
	]
})
export class ComponentsModule { }
