import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BvAngularFormsModule } from '@bv/angular-forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from './components/components.module';
import { SafeResourcesUrl } from './pipes/safe-resources-url.pipe';
import { TranslationPipe } from './pipes/translation.pipe';

const bv = [BvAngularFormsModule];

const other = [
	ComponentsModule,
	TranslateModule
];

const pipes = [
	TranslationPipe,
	SafeResourcesUrl
];


@NgModule({
	declarations: [
		SafeResourcesUrl,
		TranslationPipe
	],
	imports:  AppSharedModule.MODULE_LIST,
	exports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
        IonicModule,
		BvAngularFormsModule,
		ComponentsModule,
		BvAngularFormsModule,
		ComponentsModule,
		TranslateModule,
		SafeResourcesUrl,
		TranslationPipe
	],
	providers: [TranslationPipe]
})
export class AppSharedModule { 
	static readonly MODULE_LIST = [
		CommonModule,
        FormsModule,
        IonicModule,
        IonicModule.forRoot(),
		BvAngularFormsModule,
		ComponentsModule,
		TranslateModule.forChild()
    ];
}
