import { Component, Injector } from "@angular/core";
import { BvLanguageService } from "@bv/angular-core";
import { LoggerFactory } from "@bv/angular-logger";
import { NavController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AppController } from "./app.controller";
import { HeaderService } from "./components/header/header.service";
import { PageConstants } from "./constants/pages";

@Component({
    selector: 'app-root',
	templateUrl: "app.component.html",
	styleUrls: ['app.component.scss']
})
export class MyApp {
	// Injections
	private readonly platform = this.injector.get(Platform);
	private readonly translateSrv = this.injector.get(TranslateService);
	private readonly appCtrl = this.injector.get(AppController);
    private readonly navCtrl = this.injector.get(NavController);
	private readonly HeaderService = this.injector.get(HeaderService);
	private readonly logger = this.injector.get(LoggerFactory).buildLogger('MyApp');
	private readonly langageService = this.injector.get(BvLanguageService);
	public showHeader = this.appCtrl.onShowHeader();

	constructor(
		private readonly injector: Injector
	) {
		this.initializeApp();
	}

	/**
	 * Initialize the application.
	 *
	 * @returns {Promise<void>}
	 * @memberof MyApp
	 */
	public async initializeApp(): Promise<void> {
		this.logger.debug('Initialisation');
		await this.platform.ready();
		// Set language
		await this.configureLanguage();
		this.logger.debug('Go to loggin screen');
		this.appCtrl.getBackToLogin().subscribe(() => {
            // FIXME : Rooting ?
			this.navCtrl.navigateRoot(PageConstants.LOGIN);
			this.appCtrl.showHeader(false);
		});
		this.logger.debug('Go to loggin screen');
		this.HeaderService.onPageChanged().subscribe((page: any) => {
            // FIXME : Rooting ?
			this.navCtrl.navigateRoot(page);
			this.appCtrl.showHeader(true);
		});

		this.logger.debug('Root -> LOGIN');
		this.langageService.init().subscribe(() => {
			this.navCtrl.navigateRoot(PageConstants.LOGIN);
		});
		
	}

	/**
	 * Configure Language
	 * @private
	 */
	private async configureLanguage(): Promise<void> {
		this.translateSrv.setDefaultLang("en");
	}
}
