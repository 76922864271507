import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectUtils, StringUtils } from '@bv/angular-commons';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { AuthentificationService } from '../../../services/tech/auth.service';
import { Config } from '../../config/config';

@Injectable()
export class HttpRequestHeadersInterceptor implements HttpInterceptor {

	private readonly HEADERS = {
		AUTHORIZATION: 'Authorization',
		APIID: 'x-apigw-api-id'
	};

	constructor(private readonly authSrv: AuthentificationService, private readonly config: Config) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Store the headers
		const headers = {};
		// If has JWT
		if (this.authSrv && StringUtils.isNotBlank(this.authSrv.getToken())) {
			ObjectUtils.writeProperty(headers, this.HEADERS.AUTHORIZATION, `Bearer ${this.authSrv.getToken()}`);
		}
		// Add API ID
		const apiId = this.config.get(Constants.CONFIG.API.ID);
		if (apiId) {
			ObjectUtils.writeProperty(headers, this.HEADERS.APIID, apiId);
		}
		// Here you can update headers, example : headers['X-Requested-With'] = 'XMLHttpRequest';
		request = request.clone({
			setHeaders: headers
		});
		return next.handle(request);
	}

}
