import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageConstants } from "./constants/pages";

const routes: Routes = [
	{ path: PageConstants.LOGIN, loadChildren: () => import("./page/login/login.module").then((mod) => mod.LoginPageModule)},
	{ path: PageConstants.HOME, loadChildren: () => import("./page/home/home.module").then((mod) => mod.HomePageModule)},
  	{ path: PageConstants.GSIT, loadChildren: () => import("./page/gsit/gsit-home/gsit-home.module").then((mod) => mod.GsitHomePageModule)},
	{ path: PageConstants.ISM_PURCHASE, loadChildren: () => import("./page/ism-purchase-home/ism-purchase-home.module").then((mod) => mod.IsmPurchaseHomePageModule)},
	{ path: PageConstants.CDM, loadChildren: () => import("./page/cdm-home/cdm-home.module").then((mod) => mod.CdmHomePageModule)},
	{ path: PageConstants.AUDITOR_ASSESSMENT, loadChildren: () => import("./page/aa-home/aa-home.module").then((mod) => mod.AuditorAssessmentHomePageModule)},
	{ path: PageConstants.SHIRLEY, loadChildren: () => import("./page/shirley/shirley-home/shirley-home.module").then((mod) => mod.ShirleyHomePageModule)},
	{ path: PageConstants.SEQUENCE_6, loadChildren: () => import("./page/sequence6-home/sequence6-home.module").then((mod) => mod.Sequence6HomePageModule)},
	{ path: PageConstants.AWI, loadChildren: () => import("./page/awi-home/awi-home.module").then((mod) => mod.AwiHomePageModule)},
	{ path: PageConstants.ETQ, loadChildren: () => import("./page/etq-home/etq-home.module").then((mod) => mod.EtqHomePageModule)},
	{ path: PageConstants.ZIG, loadChildren: () => import("./page/zig-home/zig-home.module").then((mod) => mod.ZigHomePageModule)}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
