import { Component, Injector } from '@angular/core';
import { ModalController  } from '@ionic/angular';
import { Constants } from 'src/app/app.constants';
import { AuditorAssessmentConstants } from '../../../constants/auditor-assessment';
import { WebServices } from '../../../constants/webservices';
import { FieldDefinitions } from '../../../models/field-definition';
import { Config } from '../../../providers/core/config/config';
import { AuditorAssessmentService } from '../../../providers/services/business/auditor-assessment.service';
import { UrlUtils } from '../../../utils/url-utils';
import { BaseMetadataComponent } from '../../base-metadata-component';


@Component({
	selector: 'page-aa-metadata',
	templateUrl: 'aa-metadata.component.html'
})
export class AuditorAssessmentMetadataComponent extends BaseMetadataComponent {

	protected init(): void {
		const url = WebServices.parametrize(WebServices.AUDITOR_ASSESSMENT.GET_DOWNLOAD,this.model).url;
		this.downloadUrl = UrlUtils.concat(this.config.get(Constants.CONFIG.API.PATH), url);
	}

	// Injections
	private readonly viewCtrl = this.injector.get<ModalController>(ModalController);
	private readonly config = this.injector.get<Config>(Config);
	private readonly aaSrv = this.injector.get<AuditorAssessmentService>(AuditorAssessmentService);

	// Public
	public translations = new Map<string, string>();
	public downloadUrl?: string;

	constructor(protected readonly injector: Injector) {
		super(injector, 'AuditorAssessmentMetadataComponent');
	}

	public onClickClose(): void {
		this.viewCtrl.dismiss();
	}

	public onClickLink(): void {
		this.aaSrv
			.download(this.model)
			.subscribe((response: string) => {
				this.logger.debug('Download url', response);
				console.log(response);
				window.open(JSON.parse(JSON.stringify(response)).url);
			});
	}

	protected getFields(): FieldDefinitions {
		return AuditorAssessmentConstants.FIELDS_ASSESSMENT;
	}

}
