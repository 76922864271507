import {
	Component,
	EventEmitter,
	Injector,
	OnInit,
	Output,
} from "@angular/core";
import { StringUtils } from "@bv/angular-commons";
import { BvAlertController } from "@bv/ionic";
import { BaseComponent } from "../../base-component";
import { TranslateService } from "@ngx-translate/core";
import { map, Observable } from "rxjs";
import { Sequence6Constants } from "../../../constants/sequence6";
import { Sequence6Service } from "../../../providers/services/business/sequence6.service";

export class Sequence6FilterModel {
	case_number: string | undefined = undefined;
	archive_date_from: string | undefined = undefined;
	archive_date_until: string | undefined = undefined;
	category: string | undefined = undefined;
	case_label: string | undefined = undefined;
	document_type: string | undefined = undefined;
	document_label: string | undefined = undefined;
	document_date_from: string | undefined = undefined;
	document_date_until: string | undefined = undefined;
	client_name: string | undefined = undefined;
	building_city: string | undefined = undefined;
	contracting_authority: string | undefined = undefined;
	post_holder_coordinators: string | undefined = undefined;
	deputy_coordinator: string | undefined = undefined;
	is_last_version: string | undefined = undefined;
}

@Component({
	selector: "app-sequence6-filter",
	templateUrl: "sequence6-filter.component.html"
})
export class Sequence6FilterComponent extends BaseComponent implements OnInit {
	//Injections
	private readonly alertCtrl =
		this.injector.get<BvAlertController>(BvAlertController);
	public readonly sequence6Srv =
		this.injector.get<Sequence6Service>(Sequence6Service);
	private readonly translateSrv =
		this.injector.get<TranslateService>(TranslateService);

	// Input/Output
	@Output() public search = new EventEmitter();

	constructor(protected readonly injector: Injector) {
		super(injector, "Sequence6FilterModel");
	}

	public model = new Sequence6FilterModel();
	public categories: Observable<String[]> | undefined = undefined;
	public document_types: Observable<String[]> | undefined = undefined;
	public document_labels: Observable<String[]> | undefined = undefined;
	public is_last_versions: Observable<String[]> | undefined = undefined;

	public readonly NONEVALUES = {
		STRING: "NONE",
		NUMBER: -9999,
	};

	ngOnInit(): void {
		const categories = this.sequence6Srv.getFilterData(
			Sequence6Constants.FIELDS.CATEGORY
		);
		const document_types = this.sequence6Srv.getFilterData(
			Sequence6Constants.FIELDS.DOCUMENT_TYPE
		);
		const document_labels = this.sequence6Srv.getFilterData(
			Sequence6Constants.FIELDS.DOCUMENT_LABEL
		);
		const last_versions = this.sequence6Srv.getFilterData(
			Sequence6Constants.FIELDS.IS_LAST_VERSION
		);
		this.init(categories, document_types, document_labels, last_versions);

		this.model.category = this.NONEVALUES.STRING;
		this.model.is_last_version = this.NONEVALUES.STRING;
	}

	private init(
		categories: Observable<any[]>,
		document_types: Observable<any[]>,
		document_labels: Observable<any[]>,
		last_versions: Observable<any[]>
	): void {
		this.categories = categories.pipe(map((arr: string[]) => [
			this.NONEVALUES.STRING,
			...arr,
		]));
		this.document_types = document_types.pipe(map((arr: string[]) => [
			this.NONEVALUES.STRING,
			...arr,
		]));
		this.document_labels = document_labels.pipe(map((arr: string[]) => [
			this.NONEVALUES.STRING,
			...arr,
		]));
		this.is_last_versions = last_versions.pipe(map((arr: string[]) => [
			this.NONEVALUES.STRING,
			...arr,
		]));
	}

	public onChange(event: string, field: string): void {
		if (event === this.NONEVALUES.STRING) {
			this.logger.debug("onChange" + field, event);
			(this.model as any)[field] = undefined;
		}
	}

	public onClickSearch(): void {
		// Update none values
		this.model.category =
			this.model.category === this.NONEVALUES.STRING
				? undefined
				: this.model.category;
		this.model.document_type =
			this.model.document_type === this.NONEVALUES.STRING
				? undefined
				: this.model.document_type;
		this.model.document_label =
			this.model.document_label === this.NONEVALUES.STRING
				? undefined
				: this.model.document_label;
		this.model.is_last_version =
			this.model.is_last_version === this.NONEVALUES.STRING
				? undefined
				: this.model.is_last_version;
		this.logger.debug("onClickSearch", this.model);
		// If no filter defined
		if (this.filterIsEmpty()) {
           // FIXME : how fix "present" function ?
			this.alertCtrl
				.create({
					header: this.translateSrv.instant(
						"common.filter.nofilters.alert.title"
					),
					message: this.translateSrv.instant(
						"common.filter.nofilters.alert.message"
					),
					buttons: [
						{
							text: this.translateSrv.instant(
								"common.filter.nofilters.alert.button"
							),
							cssClass: "primary",
						},
					],
				});
		} else {
			this.search.emit(this.model);
		}
	}

	private filterIsEmpty(): boolean {
		return (
			StringUtils.isBlank(this.model.case_number) &&
			StringUtils.isBlank(this.model.archive_date_from) &&
			StringUtils.isBlank(this.model.archive_date_until) &&
			(StringUtils.isBlank(this.model.category) ||
				this.model.category === this.NONEVALUES.STRING) &&
			StringUtils.isBlank(this.model.case_label) &&
			(StringUtils.isBlank(this.model.document_type) ||
				this.model.document_type === this.NONEVALUES.STRING) &&
			(StringUtils.isBlank(this.model.document_label) ||
				this.model.document_label === this.NONEVALUES.STRING) &&
			StringUtils.isBlank(this.model.document_date_from) &&
			StringUtils.isBlank(this.model.document_date_until) &&
			StringUtils.isBlank(this.model.client_name) &&
			StringUtils.isBlank(this.model.building_city) &&
			StringUtils.isBlank(this.model.contracting_authority) &&
			StringUtils.isBlank(this.model.post_holder_coordinators) &&
			StringUtils.isBlank(this.model.deputy_coordinator) &&
			(StringUtils.isBlank(this.model.is_last_version) ||
				this.model.is_last_version === this.NONEVALUES.STRING)
		);
	}
}
