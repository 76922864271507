import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { StringUtils } from '@bv/angular-commons';
import { Subscription } from 'rxjs';
import { AutocompleteInterfaceService } from 'src/app/providers/services/business/autocomplete.service';
import { BaseComponent } from '../base-component';

@Component({
	selector: 'autocomplete-panel',
	templateUrl: 'autocomplete-panel.component.html',
	styleUrls: ['autocomplete-panel.component.scss']
})
export class AutocompletePanelComponent extends BaseComponent {

	// Inputs/Ouputs
	@Input()
	public inputID?: string;
	@Input()
	public service?: AutocompleteInterfaceService;
	@Input()
	public field?: string;
	@Input()
	public serviceExtraParams?: any[];
	@Output()
	public valueChange = new EventEmitter();

	public filteredDatas: any[] | undefined = undefined;
	public show = false;

	private currentSub: Subscription | undefined = undefined;
	private readonly mapAutocompleteValues = new Map<string, any[]>();
	private readonly mapLabelValues = new Map<string, (string | number)>();

	constructor(protected readonly injector: Injector) {
		super(injector, 'AutocompletePanelComponent');
	}

	ngAfterViewInit(): void {
		this.logger.debug('inputID', this.inputID);
		if (this.inputID) {
			const input = this.getInput();
			if (input) {
				input.addEventListener('focus', this.inputOnFocus.bind(this));
				input.addEventListener('blur', this.inputOnBlur.bind(this));
				input.addEventListener('keyup', this.inputOnKeyup.bind(this));
			}
		}
	}

	private loadAutocomplete(): void {
		// If has already a search
		if (this.currentSub) {
			// Stop research
			this.currentSub.unsubscribe();
			this.currentSub = undefined;
		}
		const inputValue = this.getInput().value;
		// If search could be started
		if (this.service && this.field && inputValue && inputValue.length > 2) {
			// Retreive previous result in map
			this.filteredDatas = this.mapAutocompleteValues.get(inputValue.toLowerCase());
			// If has no previous results
			if (!this.filteredDatas) {
				const inputValue = this.getInput().value;
				// Create a new search
				this.currentSub = this.service
					.getAutocompleteData(this.field, inputValue, ...(this.serviceExtraParams || []))
					.subscribe(data => {
						// Show results
						this.filteredDatas = data.map(d => {
							const label = '<p>' + StringUtils.replaceAll(String(d), inputValue, `<strong>${inputValue}</strong>`) + '</p>';
							this.mapLabelValues.set(label, d);
							return label;
						});
						// Update map
						this.mapAutocompleteValues.set(inputValue.toLowerCase(), [...this.filteredDatas]);
					});
			}
			this.show = true;
		} else {
			this.filteredDatas = undefined;
			this.show = false;
		}
	}

	/**
	 * When input has focus.
	 *
	 * @private
	 * @memberof AutocompletePanelComponent
	 */
	private inputOnFocus(): void {
		this.logger.debug('onfocus');
		this.loadAutocomplete();
	}

	/**
	 * When input lost focus.
	 *
	 * @private
	 * @memberof AutocompletePanelComponent
	 */
	private inputOnBlur(): void {
		this.logger.debug('blur');
		// Run with delay to let some time to event click
		setTimeout(() => {
			this.show = false;
		}, 250);
	}

	/**
	 * When user type on keyboard.
	 *
	 * @private
	 * @memberof AutocompletePanelComponent
	 */
	private inputOnKeyup(): void {
		this.logger.debug('keyup');
		this.loadAutocomplete();
	}


	private getInput(): HTMLInputElement {
		return document.querySelector(`ion-input[id='${this.inputID}'] input`) as HTMLInputElement;
	}

	public onClick(data: any): void {
		const value = this.mapLabelValues.get(data);
		this.logger.debug('onClick', value);
		this.valueChange.emit(value);
		this.show = false;
	}

}
