import { Component, Inject, Injector, LOCALE_ID } from '@angular/core';
import { EtqConstants } from '../../../constants/etq';
import { ApiResult } from '../../../models/api-result';
import { EtqDocumentTypeEnum } from '../../../models/etq/etq-document-type.enum';
import { FieldDefinitions } from '../../../models/field-definition';
import { EtqService } from '../../../providers/services/business/etq.service';
import { BasePageComponent } from '../../base-page-component';
import { LoaderUtils } from '@bv/ionic';
import { EtqFilterModel } from '../etq-filter/etq-filter.component';

@Component({
	selector: 'page-etq-audit',
	templateUrl: 'etq-audit.component.html'
})
export class EtqAuditComponent extends BasePageComponent {

	// Injections
	private readonly etqService = this.injector.get<EtqService>(EtqService);

	// Public
	public type = EtqDocumentTypeEnum.AUDIT;

	constructor(protected readonly injector: Injector, @Inject(LOCALE_ID) public locale: string) {
		super(injector, locale, 'EtqAuditComponent');
	}

	protected load(filter: EtqFilterModel): void {
		this.logger.debug('load', filter);
		LoaderUtils.presentLoading(this.injector).then(loading => {
		// Load data
		this.etqService
			.getAll(EtqDocumentTypeEnum.AUDIT, filter)
			.subscribe(
				(result: ApiResult<any>) => {
					this.apiResult = result;
					this.setLines(this.etqService.apiResultToModels(result));
					// Stop loading
					loading.dismiss();
				}
			);
		});
	}

	protected getFields(): FieldDefinitions {
		return EtqConstants.FIELDS_AUDIT;
	}

	protected getDateFormat(): string {
		return 'yyyy-MM-ddTHH:mm:ssZ';
	}

	public exportAuditToExcel() {
		const fileToExport = (this.lines || []).map((items:any) => { 
			return {
			 "Audit #": items?.auditRef,
			 "Audit Status": items?.auditStatus,
			 "Type": items?.docType,
			 "Section Name": items?.sectionName,
			 "CA #": items?.caRef,
			 "Action #": items?.actionRef,
			 "Effectiveness Check": items?.effCheckRef,
			 "File Name": items?.filename,
			 "Creation Date": items?.createdDate
		   } 
		  });
		this.exportToExcel(fileToExport, 'Audit-Export.xlsx');
	}
}
