import { BaseMetadataComponent } from '../../base-metadata-component';
import { Component, Injector, Inject, LOCALE_ID } from '@angular/core';
import { FieldDefinitions } from '../../../models/field-definition';
import { Observable } from 'rxjs';
import { Sequence6Constants } from '../../../constants/sequence6';
import { Sequence6DocumentModel } from '../../../models/sequence6/sequence6-document.model';
import { Sequence6Service } from '../../../providers/services/business/sequence6.service';
import { BvModalController } from '@bv/ionic';

@Component({
selector: 'page-sequence6-metadata',
templateUrl: 'sequence6-metadata.component.html'
})
export class Sequence6MetadataComponent extends BaseMetadataComponent {
    
    constructor(protected injector: Injector, @Inject(LOCALE_ID) public locale: string) {
        super(injector, 'Sequence6MetadataComponent');
    }
    
    //injections
    private readonly viewCtrl = this.injector.get<BvModalController>(BvModalController);
    private readonly sequence6Srv = this.injector.get<Sequence6Service>(Sequence6Service);
    
    // Public
    public model?: Sequence6DocumentModel;
    public showMore = false;
    
    protected getFields(): FieldDefinitions {
        return Sequence6Constants.FIELDS_JOB;
    }

    protected init(): void {

    }

    public onClickClose(): void {
		this.viewCtrl.close();
	}

    public onClickLink(): void {
        if(!this.model) {
            throw new Error('Download could not be done: no model');
        }
        let obs: Observable<string> | undefined = undefined;
        obs = this.sequence6Srv.download(this.model);
        if(obs) {
            obs.subscribe((response: string) => {
                this.logger.debug('Download url', response);
                console.log(response);
                window.open(JSON.parse(JSON.stringify(response)).url);
            })
        }
    }


}