import { FieldDefinition } from '../models/field-definition';
export abstract class Sequence6Constants {

	public static readonly FIELDS = {
		CASE_NUMBER: 'case_number',
		ARCHIVE_DATE: 'archive_date',
        ARCHIVE_DATE_FROM: 'archive_date_from',
		ARCHIVE_DATE_UNTIL: 'archive_date_until',
		CATEGORY: 'category',
		CASE_LABEL: 'case_label',
		DOCUMENT_TYPE: 'document_type',
		DOCUMENT_LABEL: 'document_label',
		DOCUMENT_DATE: 'document_date',
		DOCUMENT_DATE_FROM: 'document_date_from',
		DOCUMENT_DATE_UNTIL: 'document_date_until',
		CLIENT_NAME: 'client_name',
        BUILDING_CITY: 'building_city',
        CONTRACTING_AUTHORITY: 'contracting_authority',
		POST_HOLDER_COORDINATORS: 'post_holder_coordinators',
		DEPUTY_COORDINATOR: 'deputy_coordinator',
        IS_LAST_VERSION: 'is_last_version'
	};

	public static readonly FIELDS_JOB: {
		[key: string]: FieldDefinition
	} = {
		CASE_NUMBER: { name: 'case_number', detailMain: true, columnIndex: 1 },
		ARCHIVE_DATE_FROM: { name: 'archive_date_from'},
		ARCHIVE_DATE: { name: 'archive_date', detailMain: true, columnIndex: 2, dateFormat: 'dd/MM/yyyy' },
		ARCHIVE_DATE_UNTIL: { name: 'archive_date_until'},
		CATEGORY: { name: 'category', detailMain: true, columnIndex: 3 },
		CASE_LABEL: { name: 'case_label', detailMain: true, columnIndex: 4 },
		DOCUMENT_TYPE: { name: 'document_type', detailMain: true, columnIndex: 5  },
		DOCUMENT_LABEL: { name: 'document_label', detailMain: true, columnIndex: 6 },
		VERSION: { name: 'version', detailMain: true, columnIndex: 7 },
        DOCUMENT_DATE_FROM: { name: 'document_date_from'},
		DOCUMENT_DATE: { name: 'document_date', detailMain: true, dateFormat: 'dd/MM/yyyy' },
		DOCUMENT_DATE_UNTIL: { name: 'document_date_until'},
		CLIENT_NAME: { name: 'client_name', detailMain: true, columnIndex: 8 },
		BUILDING_CITY: { name: 'building_city', detailMain: true, columnIndex: 9 },
		CONTRACTING_AUTHORITY: { name: 'contracting_authority', detailMain: true },
		POST_HOLDER_COORDINATORS: { name: 'post_holder_coordinators', detailMain: true },
		DEPUTY_COORDINATOR: { name: 'deputy_coordinator', detailMain: true },
		ATTACHED_FILES: { name: 'attached_files', detailMain: true },
		COMMENTS: { name: 'comments', detailMain: true }
	};
}
