import { Injectable, Injector } from "@angular/core";
import * as jwt from "jsonwebtoken";
import { map, Observable } from "rxjs";
import { AppController } from "src/app/app.controller";
import { WebServices } from "src/app/constants/webservices";
import { AuthModel } from "../../../models/auth";
import { LoginModel } from "../../../models/login";
import { TokenClaimsModel } from "../../../models/token-claims";
import { UserModel } from "../../../models/user";
import { HttpService } from "../../core/http/http.service";
import { BaseService } from "../base-service";

@Injectable({ providedIn: "root" })
export class AuthentificationService extends BaseService {
	private user: UserModel | undefined;

	private token: string | undefined;

	// Injections
	private readonly appCtrl = this.injector.get(AppController);
	private readonly httpSrv = this.injector.get(HttpService);

	constructor(protected readonly injector: Injector) {
		super(injector, "AuthentificationService");
	}

	/**
	 * Store the user name.
	 *
	 * @private
	 * @param {string} username
	 * @memberof AuthentificationService
	 */
	private storeUser(user: UserModel): void {
		this.logger.debug("store user", user.username);
	}

	/**
	 * Call authentification Request.
	 *
	 * @param {LoginModel} user
	 * @returns {Observable<boolean>}
	 * @memberof AuthentificationService
	 */
	public auth(user: LoginModel): Observable<boolean> {
		const userPasswordBase64 = this.toBase64(user.username, user.password);
		const req = WebServices.parametrize(WebServices.AUTH.POST, userPasswordBase64)
		return this.httpSrv
			.call(req).pipe(
				map((result: AuthModel) => this.onAuthResponse(result))
			);
	}

	/**
	 * When auth service respond.
	 *
	 * @private
	 * @param {AuthModel} result
	 * @returns {boolean}
	 * @memberof AuthentificationService
	 */
	private onAuthResponse(result: AuthModel): boolean {
		this.token = undefined;
		if (result.valid) {
			// Decode token
			const decoded = jwt.decode(result.token) as TokenClaimsModel;
			this.logger.debug("decoded", decoded);
			// Build user
			const user = new UserModel();
			user.firstname = decoded.firstname;
			user.lastname = decoded.lastname;
			user.username = decoded.username;
			user.programCodes = decoded.bvProgramCodes;
			user.accessAllProgramCodes = decoded.accessAllProgramCodes;
			user.allowedArchives = decoded.allowedArchives;
			user.allowedDocTypes = decoded.allowedDocTypes;
			this.user = user;
			this.storeUser(user);
			// Store token
			this.token = result.token;
		}
		return result.valid;
	}

	/**
	 * Create Auhtorization header value.
	 *
	 * @private
	 * @param {string} username
	 * @param {string} password
	 * @returns {string}
	 * @memberof AuthentificationService
	 */
	private toBase64(
		username: string | undefined,
		password: string | undefined
	): string {
		const toConvert = `${username}:${password}`;
		return `Basic ${btoa(toConvert)}`;
	}

	/**
	 * Return the token value.
	 *
	 * @returns {string}
	 * @memberof AuthentificationService
	 */
	public getToken(): string | undefined {
		return this.token;
	}

	/**
	 * Check if user is logged or not.
	 *
	 * @returns {boolean}
	 * @memberof AuthentificationService
	 */
	public isLogged(): boolean {
		return this.user !== undefined;
	}

	/**
	 * Logout the user.
	 *
	 * @memberof AuthentificationService
	 */
	public logout(): void {
		this.user = undefined;
		this.token = undefined;
		this.appCtrl.backToLogin();
	}

	/**
	 * Return the current logged user.
	 *
	 * @returns {UserModel}
	 * @memberof AuthentificationService
	 */
	public getUser(): UserModel | undefined {
		return this.user;
	}
}
