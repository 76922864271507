import { FieldDefinition } from '../models/field-definition';
export abstract class ShirleyConstants {

	public static readonly FIELDS = {
		JOB_NUMBER: 'job_number',
		OFFICE: 'office',
		CREATION_DATE_FROM: 'creation_date_from',
		CREATION_DATE_UNTIL: 'creation_date_until',
		PORT: 'port',
		CUSTOMER: 'customer',
		VESSEL: 'vessel',
		PRODUCT: 'product',
		TERMINAL: 'terminal',
		JOB_STATUS: 'status',
		CARGO_OPERATION: 'cargo_operation'
	};

	public static readonly FIELDS_JOB: {
		[key: string]: FieldDefinition
	} = {
		JOB_NUMBER: { name: 'job_number', detailMain: true, columnIndex: 1 },
		OFFICE: { name: 'office', detailMain: true, columnIndex: 3 },
		CREATION_DATE_FROM: { name: 'creation_date_from'},
		CREATION_DATE: { name: 'creation_date', detailMain: true, columnIndex: 8, dateFormat: 'yyyy/MM/dd' },
		CREATION_DATE_UNTIL: { name: 'creation_date_until'},
		PORT: { name: 'port', detailMain: true, columnIndex: 4 },
		CUSTOMER: { name: 'customer', detailMain: true, columnIndex: 5 },
		VESSEL: { name: 'vessel', detailMain: true, columnIndex: 7  },
		PRODUCT: { name: 'product', detailMain: true, columnIndex: 6 },
		TERMINAL: { name: 'terminal', detailMain: true },
		JOB_STATUS: { name: 'status', detailMain: true, columnIndex: 2 },
		CARGO_OPERATION: { name: 'cargo_operation', detailMain: true, columnIndex: 9},
		INSPECTOR: { name: 'inspector', detailMain: true},
		JOB_TYPE: { name: 'job_type', detailMain: true},
		SCHEDULED_DATE: { name: 'scheduled_date', detailMain: true, dateFormat: 'yyyy/MM/dd HH:mm:ss'}
		};
}
