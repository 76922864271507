import { Component, Injector } from '@angular/core';
import { FieldDefinitions } from '../../../models/field-definition';
import { Sequence6Constants } from '../../../constants/sequence6';
import { BasePageComponent } from '../../base-page-component';
import { LoaderUtils } from '@bv/ionic';
import { Sequence6Service } from '../../../providers/services/business/sequence6.service';
import { ApiResult } from '../../../models/api-result';
import { Sequence6DocumentModel } from '../../../models/sequence6/sequence6-document.model';
import { Sequence6FilterModel } from '../sequence6-filter/sequence6-filter.component';

@Component({
    selector: 'page-sequence6-job',
    templateUrl: 'sequence6-job.component.html'
  })
  export class Sequence6JobComponent extends BasePageComponent {
    
    constructor(protected injector: Injector) {
      super(injector, 'Sequence6JobComponent');
    }

    //injections
    public readonly sequence6Srv = this.injector.get<Sequence6Service>(Sequence6Service);

    protected load(filter: Sequence6FilterModel): void {
      // Start loading
      LoaderUtils.presentLoading(this.injector).then(loading => {
        // Load data
        this.sequence6Srv.getAll(filter)
          .subscribe(
            (result: ApiResult<Sequence6DocumentModel>) => {
              this.apiResult = result;
              this.lines = this.sequence6Srv.apiResultToModels(result);
              // Stop loading
              loading.dismiss();
            },
            (error: any) => {
              this.logger.error('Error during loading', error);
              // Stop loading
              loading.dismiss();
            }
          );
      });
    }

    protected getFields(): FieldDefinitions {
      return Sequence6Constants.FIELDS_JOB;
    }
}