import { Injector } from '@angular/core';
import { Logger, LoggerFactory } from '@bv/angular-logger';
import { BvLoadingController } from '@bv/ionic';

export abstract class BaseComponent {

	// Injection
	private readonly loadingCtrl = this.injector.get<BvLoadingController>(BvLoadingController);

	protected logger: Logger;

	constructor(
		protected readonly injector: Injector,
		protected readonly loggerName = 'BaseComponent'
	) {
		this.logger = injector.get(LoggerFactory).buildLogger(loggerName);
	}

	protected loading(load = true): void {
		if (load) {
			this.loadingCtrl.start();
		} else {
			this.loadingCtrl.stop();
		}
	}
}
