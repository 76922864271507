import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ShirleyFilterModel } from '../../../components/shirley/shirley-filter/shirley-filter.component';
import { WebServices } from '../../../constants/webservices';
import { ApiResult } from '../../../models/api-result';
import { ShirleyDocumentModel } from '../../../models/shirley/shirley-document.model';
import { HttpService } from '../../core/http/http.service';
import { BaseService } from '../base-service';
import { ApiService } from '../tech/api.service';
import { AutocompleteInterfaceService } from './autocomplete.service';

@Injectable()
export class ShirleyJobService extends BaseService implements AutocompleteInterfaceService {

	// Injections
	private readonly httpSrv = this.injector.get<HttpService>(HttpService)
	private readonly apiSrv = this.injector.get<ApiService>(ApiService);

	constructor(protected readonly injector: Injector) {
		super(injector, 'ShirleyJobService');
	}

	public getAll(filters: ShirleyFilterModel): Observable<ApiResult<ShirleyDocumentModel>> {
		const req = WebServices.parametrize(WebServices.SHIRLEY_JOB.GET_ALL, filters);
		return this.httpSrv.call(req);
	}

	public apiResultToModels(res: ApiResult<ShirleyDocumentModel>): ShirleyDocumentModel[] {
		return res.hits.hits.map(hit => { const res = hit._source; res.id = hit._id; return res; });
	}

	public getFilterData(field: string): Observable<(string | number)[]> {
		const req = WebServices.parametrize(WebServices.SHIRLEY_JOB.GET_FILTERDATA, field);
		 return this.httpSrv.call(req).pipe(
			map(
		 		(res: ApiResult<ShirleyDocumentModel>) => this.apiSrv.extractAggregation(res)
		 	)
		 );
	}

	public download(doc: ShirleyDocumentModel): Observable<string> {
		const req = WebServices.parametrize(WebServices.SHIRLEY_JOB.GET_DOWNLOAD, doc);
		return this.httpSrv.call(req);
	}

	public getAutocompleteData(field: string, value: any): Observable<(string | number)[]> {
		const req = WebServices.parametrize(WebServices.SHIRLEY_JOB.GET_AUTOCOMPLETEDATA, field, value);
		return this.httpSrv
			.call(req).pipe(
				map(
					(res: ApiResult<any>) => this.apiSrv.extractAggregation(res)
				)
		);
	}

}
