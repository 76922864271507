export abstract class PageConstants {
	// LOGIN
	public static readonly LOGIN = 'login';
	// HOME
	public static readonly HOME = 'home';
	// GSIT HOME
	public static readonly GSIT = 'gsit';
	public static readonly ISM_PURCHASE = 'ism-purchase-home';
	public static readonly CDM = 'cdm-home';
	public static readonly AUDITOR_ASSESSMENT = 'aa-home';
	public static readonly SHIRLEY = 'shirley-home';
	public static readonly SEQUENCE_6 = 'sequence6-home';
	public static readonly AWI = 'awi-home';
	public static readonly ETQ = 'etq-home';
	public static readonly ZIG = 'zig-home';
	public static readonly NOT_DEFINED = 'not-defined';
}
