import { Component, Inject, Injector, LOCALE_ID } from '@angular/core';
import { AuditorAssessmentConstants } from '../../../constants/auditor-assessment';
import { ApiResult } from '../../../models/api-result';
import { FieldDefinitions } from '../../../models/field-definition';
import { AuditorAssessmentService } from '../../../providers/services/business/auditor-assessment.service';
import { BasePageComponent } from '../../base-page-component';
import { AuditorAssessmentFilterModel } from '../aa-filter/aa-filter.component';

@Component({
	selector: 'page-aa-assessment',
	templateUrl: 'aa-assessment.component.html'
})
export class AuditorAssessmentAssessmentComponent extends BasePageComponent {

	// Injections
	private readonly aaService = this.injector.get<AuditorAssessmentService>(AuditorAssessmentService);

	constructor(protected readonly injector: Injector, @Inject(LOCALE_ID) public locale: string) {
		super(injector, locale, 'AuditorAssessmentAssessmentComponent');
	}

	protected load(filter: AuditorAssessmentFilterModel): void {
		this.logger.debug('load', filter);
		this.loading();
		// Load data
		this.aaService
			.getAll(filter)
			.subscribe(
				(result: ApiResult<any>) => {
					this.apiResult = result;
					this.setLines(this.aaService.apiResultToModels(result));
					// Stop loading
					this.loading(false);
				}
			);
	}

	protected getFields(): FieldDefinitions {
		return AuditorAssessmentConstants.FIELDS_ASSESSMENT;
	}

}
