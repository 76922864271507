import {
	Component,
	DoCheck,
	Inject,
	Injector,
	KeyValueDiffer,
	KeyValueDiffers,
} from "@angular/core";
import { Logger, LoggerFactory } from "@bv/angular-logger";
import { BvAlertController, BvLoadingController } from "@bv/ionic";
import { Keyboard } from "@capacitor/keyboard";
import { LoadingController, NavController } from "@ionic/angular";
import { BehaviorSubject, filter, first, Subscription } from "rxjs";
import { AuthentificationService } from "../providers/services/tech/auth.service";

@Component({
	template: "",
})
export abstract class BasePage /*implements DoCheck*/ {
	protected readonly logger: Logger;

	private readonly subscribers: Subscription[] = [];

	// Keyboard
	//private readonly differKeyboard: KeyValueDiffer<{}, {}>;
	private keyboardOpened = false;
	#loadCount = 0;
	#loadingClosable = new BehaviorSubject<boolean>(false);

	// Injecton
	protected readonly alertCtrl = this.injector.get(BvAlertController);
	protected readonly authSrv = this.injector.get(AuthentificationService);
	protected readonly navCtrl = this.injector.get<NavController>(
		NavController as any
	);
	#loadingCtrl = this.injector.get<LoadingController>(LoadingController);
	constructor(
		protected readonly injector: Injector,
		@Inject("loggerName") protected readonly loggerName = "AbstractPage",
		@Inject("authentificationRequired")
		protected readonly authentificationRequired = true
	) {
		this.logger = injector.get(LoggerFactory).buildLogger(loggerName);

		// Watch for difference
		/*this.differKeyboard = this.injector.get(KeyValueDiffers)
			.find(this.keyValueKeyboardOpen())
			.create();*/
	}

	/*public ngDoCheck(): void {
		// If keyboard state has changed
		const change = this.differKeyboard.diff(this.keyValueKeyboardOpen());
		if (change) {
			//this.onKeyboardStateChange();
		}
	}*/

	/**
	 * When keyboard state has changed.
	 *
	 * @returns {boolean}
	 * @memberof BasePage
	 */
	private onKeyboardStateChange(): boolean {
		// No injection in constructor to evict issue
		/*const value = this.injector.get(Keyboard).isOpen();
		if (value === this.keyboardOpened) {
			return value;
		}
		this.keyboardOpened = value;
		if (this.keyboardOpened) {
			document.body.classList.add('keyboard-open');
		} else {
			document.body.classList.remove('keyboard-open');
		}*/
		//return value;
		return false;
	}

	/**
	 * Return the key value for keyboard open.
	 *
	 * @private
	 * @returns {{ keyboardopen: boolean }}
	 * @memberof BasePage
	 */
	/*private keyValueKeyboardOpen(): { keyboardopen: boolean } {
		return { keyboardopen: this.injector.get(Keyboard).isOpen() };
	}

	public ionViewCanEnter(): boolean {
		// If auth is required and user is not logged
		if (this.authentificationRequired && !this.authSrv.isLogged()) {
			this.logger.warn('Authentification required, redirect to login page');
			// Go to login form
			setTimeout(() => this.authSrv.logout());
			return false;
		} else {
			return true;
		}
	}

	public ionViewWillUnload(): void {
		this.unregisterSubscribers();
	}

	/**
	 * Register a subscriber.
	 *
	 * @protected
	 * @param {ISubscription} sub
	 * @memberof BasePage
	 */
	protected registerSubscriber<T extends Subscription>(sub: T): T {
		this.subscribers.push(sub);
		return sub;
	}

	/**
	 * Unregister all subsribers (usefull for perf).
	 *
	 * @private
	 * @memberof BasePage
	 */
	private unregisterSubscribers(): void {
		this.logger.debug("Unregister all subsribers", this.subscribers);
		if (this.subscribers) {
			this.subscribers.forEach((sub) => sub.unsubscribe());
		}
	}

	/**
	 * Create and show a loading modal.
	 * @returns {Promise<Loading>} the loading modal
	 */
	public loading(load = true): void {
		this.logger.debug("loading", load);
		if (load) {
			this.#loadCount++;
			if (this.#loadCount === 1) {
				const loading = this.#loadingCtrl.create();
				loading.then((r) =>
					r.present().then(() => this.#loadingClosable.next(true))
				);
			}
		} else {
			this.#loadCount--;
			if (this.#loadCount === 0) {
				this.#loadingClosable
					.pipe(
						filter((closable) => closable),
						first()
					)
					.subscribe(() =>
						setTimeout(() => {
							this.#loadingCtrl
								.dismiss()
								.catch((error) =>
									this.logger.debug(
										"Error when close loader",
										error?.message
									)
								);
						}, 500)
					);
			}
		}
	}
}
