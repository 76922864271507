
export abstract class UrlUtils {

	/**
	 * Add parameters in a URL.
	 *
	 * @static
	 * @param {string} baseUrl
	 * @param {{
	 * 			key: string,
	 * 			value: any
	 * 		}[]} params
	 * @returns {string}
	 * @memberof UrlUtils
	 */
	public static addParameters(
		baseUrl: string,
		params: {
			key: string,
			value: any
		}[]
	): string {
		let finalUrl = baseUrl;
		params.forEach(param => {
			if (param.value) {
				finalUrl += finalUrl === baseUrl ? '?' : '&';
				finalUrl += `${param.key}=${param.value}`;
			}
		});
		return finalUrl || baseUrl;
	}

	public static concat(...parts: any[]): string {
		let finalUrl = '';
		parts
			.filter(part => part !== undefined)
			.forEach(part => {
				if (finalUrl !== ''
					&& !part.startsWith('/') && !part.startsWith('\\')
					&& !finalUrl.endsWith('/') && !finalUrl.endsWith('//')
				) {
					finalUrl += '/';
				}
				finalUrl += part;
			});
		return finalUrl;
	}
}
