import { Component, Injector } from '@angular/core';
import { LoaderUtils } from '@bv/ionic';
import { ShirleyJobService } from 'src/app/providers/services/business/shirley-job.service';
import { ShirleyConstants } from '../../../constants/shirley';
import { ApiResult } from '../../../models/api-result';
import { FieldDefinitions } from '../../../models/field-definition';
import { ShirleyDocumentModel } from '../../../models/shirley/shirley-document.model';
import { BasePageComponent } from '../../base-page-component';
import { ShirleyFilterModel } from '../shirley-filter/shirley-filter.component';

@Component({
	selector: 'page-shirley-job',
	templateUrl: 'shirley-job.component.html'
})
export class ShirleyJobComponent extends BasePageComponent {

	// Injections
	private readonly shirleySrv = this.injector.get<ShirleyJobService>(ShirleyJobService);
	constructor(protected injector: Injector) {
		super(injector, 'ShirleyJobComponent');
	}

	protected load(filter: ShirleyFilterModel): void {
		// Start loading
		LoaderUtils.presentLoading(this.injector).then(loading => {
			// Load data
			this.shirleySrv.getAll(filter)
				.subscribe(
					(result: ApiResult<ShirleyDocumentModel>) => {
						this.apiResult = result;
						this.lines = this.shirleySrv.apiResultToModels(result);
						// Stop loading
						loading.dismiss();
					},
					(error: any) => {
						this.logger.error('Error during loading', error);
						// Stop loading
						loading.dismiss();
					}
				);
		});
	}

	protected getFields(): FieldDefinitions {
		return ShirleyConstants.FIELDS_JOB;
	}
}
