import { FieldDefinition } from '../models/field-definition';

export abstract class AuditorAssessmentConstants {

	public static readonly FIELDS_ASSESSMENT: {
		[key: string]: FieldDefinition
	} = {
			LAST_NAME: { name: 'cs_last_name', detailMain: true, columnIndex: 1 },
			FIRST_NAME: { name: 'cs_first_name', detailMain: true, columnIndex: 2 },
			REFERENTIAL: { name: 'cs_referential', detailMain: true, columnIndex: 3 },
			AUDITOR_STATUS: { name: 'cs_auditor_status', detailMain: true, columnIndex: 4 },
			ACTIVITY_CODE: { name: 'cs_activity_code', detailMain: true, columnIndex: 5 },
			MANAGER: { name: 'cs_manager', detailMain: true, columnIndex: 6 },
			STATUS: { name: 'cs_status', detailMain: true, columnIndex: 7 },
			COUNTRY: { name: 'cs_country', detailMain: true, columnIndex: 8 },
			VERSION_LABEL: { name: 'r_version_label', detailMain: true, columnIndex: 9 },
			DOCUMENT_NAME: { name: 'object_name', detailMain: true},
			SUBMITTED_DATE: { name: 'cs_submitted_date', detailMain: true},
			COMMENT: { name: 'cs_comment', detailMain: true },
			BU: { name: 'cs_bu', detailMain: true },
			IS_LAST_VERSION: { name: 'is_last_version'},
			AUDITOR_EMAIL: { name: 'cs_auditor_email', detailMain: true }
		};

}
