import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { StringUtils } from '@bv/angular-commons';
import { BaseComponent } from '../../base-component';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { AwiService } from '../../../providers/services/business/awi.service';
import { AwiConstants } from '../../../constants/awi';
import { BvAlertController } from '@bv/ionic';

export class AwiFilterModel {
    proposal_num: string | undefined = undefined;
    proposal_issuing_year: string | undefined = undefined;
    proposal_bu: string | undefined = undefined;
    client_zig_number: string | undefined = undefined;
    proposal_zig_contract_num: string | undefined = undefined;
}

@Component({
    selector: 'app-awi-filter',
    templateUrl: 'awi-filter.component.html'
})
export class AwiFilterComponent extends BaseComponent implements OnInit {

    //Injections
    private readonly alertCtrl = this.injector.get<BvAlertController>(BvAlertController);
    public readonly awiSrv = this.injector.get<AwiService>(AwiService);
    private readonly translateSrv = this.injector.get<TranslateService>(TranslateService);

    // Input/Output
    @Output() public search = new EventEmitter();

    constructor(protected readonly injector: Injector) {
        super(injector, 'AwiFilterModel');
    }

    public model = new AwiFilterModel();
    public proposal_issuing_years: Observable<String[]> | undefined = undefined;

    public readonly NONEVALUES = {
        STRING: 'NONE',
        NUMBER: -9999
    };

    ngOnInit(): void {
        const proposal_issuing_years = this.awiSrv.getFilterData(AwiConstants.FIELDS.ISSUING_YEAR);
        this.init(proposal_issuing_years);
        this.model.proposal_issuing_year = this.NONEVALUES.STRING;
    }

    private init(proposal_issuing_years: Observable<any[]>): void {
        this.proposal_issuing_years = proposal_issuing_years.pipe(map((arr: string[]) => [this.NONEVALUES.STRING, ...arr]));
    }

    public onChange(event: string, field: string): void {
        if (event === this.NONEVALUES.STRING) {
            this.logger.debug('onChange' + field, event);
            (this.model as any)[field] = undefined;
        }
    }

    public onClickSearch(): void {
        // Update none values
        this.model.proposal_issuing_year = this.model.proposal_issuing_year === this.NONEVALUES.STRING ? undefined : this.model.proposal_issuing_year;
        this.logger.debug('onClickSearch', this.model);
        // If no filter defined
        if (this.filterIsEmpty()) {
            this.alertCtrl
                .create({
                    header: this.translateSrv.instant('common.filter.nofilters.alert.title'),
                    message: this.translateSrv.instant('common.filter.nofilters.alert.message'),
                    buttons: [
                        {
                            text: this.translateSrv.instant('common.filter.nofilters.alert.button'),
                            cssClass: 'primary'
                        }
                    ]
                });
        } else {
            this.search.emit(this.model);
        }
    }

    private filterIsEmpty(): boolean {
        return (StringUtils.isBlank(this.model.proposal_num)
            && (StringUtils.isBlank(this.model.proposal_issuing_year) || this.model.proposal_issuing_year === this.NONEVALUES.STRING)
            && StringUtils.isBlank(this.model.proposal_bu)
            && StringUtils.isBlank(this.model.client_zig_number)
            && StringUtils.isBlank(this.model.proposal_zig_contract_num));
    }
}
