import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injector } from '@angular/core';
import { ArrayUtils } from '@bv/angular-commons';
import { LoggerFactory } from '@bv/angular-logger';
import { BvLoadingController } from '@bv/ionic';
import { MessageService } from './message.service';

export class CustomErrorHandler implements ErrorHandler {

	// Injections
	private readonly logger = this.injector.get<LoggerFactory>(LoggerFactory).buildLogger('CustomErrorHandler');

	private readonly errorsToIgnore = [
		// When no image selected with camera (normal behavior when cancel or back)
		'Uncaught (in promise): No Image Selected',
		// When cancel choose from library and back form (IOS)
		'Uncaught (in promise): has no access to assets'
	];

	// Injections
	// no injections here to evict issues

	constructor(private readonly injector: Injector) { }

	public handleError(error: any): void {
		this.logger.error('Handled error', error);
		// Show error
		const message = this.getErrorMessage(error);
		// If message is not to ignore
		if (!ArrayUtils.contains(this.errorsToIgnore, message)) {
			this.injector.get<MessageService>(MessageService).showError({ messageKey: message });
			// Stop loading spinner
			this.injector.get<BvLoadingController>(BvLoadingController).forceStop();
		}
	}

	/**
	 * Retreive the error message.
	 *
	 * @private
	 * @param {*} error
	 * @returns {string}
	 * @memberof CustomErrorHandler
	 */
	private getErrorMessage(error: any): string | undefined {
		if (error instanceof HttpErrorResponse) {
			if (error.status === 403) {
				return 'common.response.error.unauthorized';
			} else {
				return 'common.response.error.expectation.failed';
			}
		} else if (error.message) {
			return error.message;
		}
		return undefined;
	}

}
