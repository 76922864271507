import { Component, Injector, OnInit } from '@angular/core';
import { ArrayUtils } from '@bv/angular-commons';
import { UserModel } from 'src/app/models/user';
import { PageConstants } from '../../constants/pages';
import { AuthentificationService } from '../../providers/services/tech/auth.service';
import { BaseComponent } from '../base-component';
import { HeaderService } from './header.service';

interface PageDescription {
	name: string;
	page: string;
}

@Component({
	selector: 'bv-header',
	templateUrl: 'header.component.html',
	styleUrls: ['header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

	// Injections
	private readonly authSrv = this.injector.get<AuthentificationService>(AuthentificationService);
	private readonly headerCtrl = this.injector.get<HeaderService>(HeaderService);

	public userPages: PageDescription[] = [{
		name: 'GSIT',
		page: PageConstants.GSIT
		/*}, {
			name: 'Other',
			page: PageConstants.NOT_DEFINED*/
	}];

	public currentPage = this.headerCtrl.getCurrentPage();

	private static isFirst = true;

	constructor(protected readonly injector: Injector) {
		super(injector, 'HeaderComponent');
	}

	ngOnInit(): void {
		const pages = [];
		let user = this.authSrv.getUser();
		// user = {allowedArchives: ['*']};
		if (user) {
			const allowedArchives = user.allowedArchives;
			const isAll = ArrayUtils.contains(allowedArchives, '*');

			// GSIT
			if (isAll || ArrayUtils.contains(allowedArchives, 'GSIT')) {
				pages.push({
					name: 'GSIT',
					page: PageConstants.GSIT
				});
			}
			// ISM
			if (isAll || ArrayUtils.contains(allowedArchives, 'ISM_PURCHASE')) {
				pages.push({
					name: 'ISM Purchase',
					page: PageConstants.ISM_PURCHASE
				});
			}
			// CDM
			if (isAll || ArrayUtils.contains(allowedArchives, 'CDM')) {
				pages.push({
					name: 'CDM JI',
					page: PageConstants.CDM
				});
			}
			// AUDITOR_ASSESSMENT
			if (isAll || ArrayUtils.contains(allowedArchives, 'AUDITOR_ASSESSMENT')) {
				pages.push({
					name: 'AUDITOR ASSESSMENT',
					page: PageConstants.AUDITOR_ASSESSMENT
				});
			}
			if (isAll || ArrayUtils.contains(allowedArchives, 'SHIRLEY')) {
				pages.push({
					name: 'SHIRLEY MAIA',
					page: PageConstants.SHIRLEY
				});
			}
			if (isAll || ArrayUtils.contains(allowedArchives, 'SEQUENCE6')) {
				pages.push({
					name: 'SEQUENCE 6',
					page: PageConstants.SEQUENCE_6
				});
			}
			if (isAll || ArrayUtils.contains(allowedArchives, 'AWI')) {
				pages.push({
					name: 'AWI',
					page: PageConstants.AWI
				});
			}
			if (isAll || ArrayUtils.contains(allowedArchives, 'ETQ')) {
				pages.push({
					name: 'ETQ',
					page: PageConstants.ETQ
				});
			}
			if (isAll || ArrayUtils.contains(allowedArchives, 'ZIG')) {
				pages.push({
					name: 'ZIG',
					page: PageConstants.ZIG
				});
			}
			if (HeaderComponent.isFirst) {
				this.headerCtrl.changePage(pages[0].page);
				HeaderComponent.isFirst = false;
			}
		}
		this.userPages = pages;
	}

	public onClickLogout(): void {
		this.authSrv.logout();
		this.headerCtrl.changePage(PageConstants.LOGIN);
		HeaderComponent.isFirst = true;
	}

	public onClickOpenPage(desc: PageDescription): void {
		this.headerCtrl.changePage(desc.page);
	}

	public onClickTitle(): void {
		this.headerCtrl.changePage(PageConstants.HOME);
	}

}
