import { Injectable, Injector } from "@angular/core";
import { map, Observable } from "rxjs";
import { Sequence6FilterModel } from "../../../components/sequence6/sequence6-filter/sequence6-filter.component";
import { WebServices } from "../../../constants/webservices";
import { ApiResult } from "../../../models/api-result";
import { Sequence6DocumentModel } from "../../../models/sequence6/sequence6-document.model";
import { HttpService } from "../../core/http/http.service";
import { BaseService } from "../base-service";
import { ApiService } from "../tech/api.service";
import { AutocompleteInterfaceService } from "./autocomplete.service";

@Injectable()
export class Sequence6Service
	extends BaseService
	implements AutocompleteInterfaceService
{
	//Injections
	private readonly httpSrv = this.injector.get<HttpService>(HttpService);
	private readonly apiSrv = this.injector.get<ApiService>(ApiService);

	//constructor
	constructor(protected readonly injector: Injector) {
		super(injector, "Sequence6Service");
	}

	public getFilterData(field: string): Observable<(string | number)[]> {
		return this.httpSrv
			.call(WebServices.parametrize(WebServices.SEQUENCE_6.GET_FILTERDATA,field))
			.pipe(
				map((res: ApiResult<Sequence6DocumentModel>) =>
					this.apiSrv.extractAggregation(res)
				)
			);
	}

	public getAll(
		filters: Sequence6FilterModel
	): Observable<ApiResult<Sequence6DocumentModel>> {
		return this.httpSrv.call(
			WebServices.parametrize(WebServices.SEQUENCE_6.GET_ALL,filters)
		);
	}

	public download(
		model: Sequence6DocumentModel
	): Observable<string> | undefined {
		return this.httpSrv.call(
			WebServices.parametrize(WebServices.SEQUENCE_6.GET_DOWNLOAD,model)
		);
	}

	public getAutocompleteData(
		field: string,
		value: any
	): Observable<(string | number)[]> {
		return this.httpSrv
			.call(
				WebServices.parametrize(WebServices.SEQUENCE_6.GET_AUTOCOMPLETEDATA,field,
					value
				)
			)
			.pipe(
				map((res: ApiResult<any>) =>
					this.apiSrv.extractAggregation(res)
				)
			);
	}

	public apiResultToModels(res: ApiResult<Sequence6DocumentModel>): Sequence6DocumentModel[] {
		return res.hits.hits.map((hit) => {
			const res = hit._source;
			res.id = hit._id;
			return res;
		});
	}
}
