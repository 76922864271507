import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeResourcesUrl' })
export class SafeResourcesUrl {

	constructor(private readonly sanitizer: DomSanitizer) { }

	transform(url: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
