import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppSharedModule } from 'src/app/app-shared.module';
import { HomePage } from './home.component';
import { HomeRoutingModule } from './home.routing.module';

@NgModule({
	declarations: [
		HomePage
	],
	imports: [
		AppSharedModule,
		HomeRoutingModule,
		IonicModule.forRoot(),
	],
})
export class HomePageModule { }
