import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppSharedModule } from 'src/app/app-shared.module';
import { EtqFilterComponent } from './etq-filter/etq-filter.component';
import { EtqMetadataComponent } from './etq-metadata/etq-metadata.component';
import { EtqAuditComponent } from './etq-audit/etq-audit.component';
import { EtqIncidentComponent } from './etq-incident/etq-incident.component';
import { EtqComplaintComponent } from './etq-complaint/etq-complaint.component';

@NgModule({
	declarations: [
		EtqFilterComponent,
		EtqMetadataComponent,
		EtqAuditComponent,
		EtqIncidentComponent,
		EtqComplaintComponent
	],
	imports: [
		AppSharedModule,
		IonicModule.forRoot()
	],
	exports: [
		EtqAuditComponent,
		EtqIncidentComponent,
		EtqComplaintComponent
	]
})
export class EtqComponentsModule { }
