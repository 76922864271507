import { Component, Inject, Injector, LOCALE_ID } from '@angular/core';
import { EtqConstants } from '../../../constants/etq';
import { ApiResult } from '../../../models/api-result';
import { EtqDocumentTypeEnum } from '../../../models/etq/etq-document-type.enum';
import { FieldDefinitions } from '../../../models/field-definition';
import { EtqService } from '../../../providers/services/business/etq.service';
import { BasePageComponent } from '../../base-page-component';
import { LoaderUtils } from '@bv/ionic';
import { EtqFilterModel } from '../etq-filter/etq-filter.component';

@Component({
	selector: 'page-etq-complaint',
	templateUrl: 'etq-complaint.component.html'
})
export class EtqComplaintComponent extends BasePageComponent {

	// Injections
	private readonly etqService = this.injector.get<EtqService>(EtqService);

	// Public
	public type = EtqDocumentTypeEnum.COMPLAINT;

	constructor(protected readonly injector: Injector, @Inject(LOCALE_ID) public locale: string) {
		super(injector, locale, 'EtqComplaintComponent');
	}

	protected load(filter: EtqFilterModel): void {
		this.logger.debug('load', filter);
		LoaderUtils.presentLoading(this.injector).then(loading => {
			// Load data
			this.etqService
				.getAll(EtqDocumentTypeEnum.COMPLAINT, filter)
				.subscribe(
					(result: ApiResult<any>) => {
						this.apiResult = result;
						this.setLines(this.etqService.apiResultToModels(result));
						// Stop loading
						loading.dismiss();
					}
				);
			});
	}

	protected getFields(): FieldDefinitions {
		return EtqConstants.FIELDS_COMPLAINT;
	}

	protected getDateFormat(): string {
		return 'yyyy-MM-ddTHH:mm:ssZ';
	}

	public exportComplaintToExcel() {
		const fileToExport = (this.lines || []).map((items:any) => { 
			console.debug(items);
			return {
			 "Complaint #": items?.complaintRef,
			 "Complaint Status": items?.complaintStatus,
			 "Type": items?.docType,
			 "Section Name": items?.sectionName,
			 "CA #": items?.caRef,
			 "Action #": items?.actionRef,
			 "Effectiveness Check": items?.effCheckRef,
			 "File Name": items?.filename,
			 "Creation Date": items?.createdDate
		   } 
		  });
		this.exportToExcel(fileToExport, 'Complaint-Export.xlsx');
	}

}
