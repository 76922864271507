import { Injectable } from "@angular/core";
import { ToastController, ToastOptions } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

export interface IMessageOptions extends ToastOptions {
	/**
	 * Bundle key
	 */
	messageKey?: string;

	/**
	 * Message from server
	 */
	messageServer?: string;

	/**
	 * Object containing the parameters to be replaced in the string
	 */
	messageParams?: any;

	/**
	 * The error throwed
	 */
	error?: Error;

	/**
	 * The error label, for a best localization of the problem
	 */
	errorLabel?: string;
}

@Injectable({ providedIn: "root" })
export class MessageService {
	constructor(
		private readonly toastCtrl: ToastController,
		private readonly translate: TranslateService
	) {}

	/**
	 * Show an error toaster.
	 * @param options MessageOptions, contains i18n key message to show
	 */
	public async showError(options: IMessageOptions) {
		options.cssClass = "toast-error";
		return await this.showMessage(options);
	}

	/**
	 * Show an success toaster.
	 * @param options MessageOptions, contains i18n key message to show
	 */
	public async showSuccess(options: IMessageOptions) {
		options.cssClass = "toast-success";
		return await this.showMessage(options);
	}

	/**
	 * Show an message toaster.
	 * @param options MessageOptions, contains i18n key message to show
	 */
	public async showMessage(options: IMessageOptions) {
		const opts = this.transformOptions(options);
		const t = await this.toastCtrl.create(opts);
		await t.present();
	}

	/**
	 * Show an warning toaster.
	 * @param options MessageOptions, contains i18n key message to show
	 */
	public async showWarning(options: IMessageOptions) {
		options.cssClass = "toast-warning";
		return await this.showMessage(options);
	}

	private transformOptions(options: IMessageOptions): IMessageOptions {
		// close button

		// duration
		if (!options.duration) {
			options.duration = 3000;
		}

		// position
		if (!options.position) {
			options.position = "top";
		}

		// message
		const { messageKey, messageServer } = options;
		if (messageKey) {
			options.message = this.translate.instant(
				messageKey,
				options.messageParams
			);
		}
		if (messageServer) {
			options.message = messageServer;
		}
		return options;
	}
}
