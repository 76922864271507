import { FieldDefinition } from '../models/field-definition';

export abstract class EtqConstants {

	public static readonly FIELDS_AUDIT: {
		[key: string]: FieldDefinition
	} = {
			AUDIT_NUMBER: { name: 'auditRef', detailMain: true, columnIndex: 1 },
			AUDIT_STATUS: { name: 'auditStatus', detailMain: true, columnIndex: 2 },
			DOC_TYPE: { name: 'docType', detailMain: true, columnIndex: 3 },
			SECTION_NAME: { name: 'sectionName', detailMain: true, columnIndex: 4 },
			CHECKLIST_NUMBER: { name: 'checklistRef', detailMain: true, columnIndex: 5 },
			CA_NUMBER: { name: 'caRef', detailMain: true, columnIndex: 6 },
			ACTION_NUMBER: { name: 'actionRef', detailMain: true, columnIndex: 7 },
			EFF_CHECK_NUMBER: { name: 'effCheckRef', detailMain: true, columnIndex: 8 },
			FILE_NAME: { name: 'filename', detailMain: true, columnIndex: 9 },
			CREATION_DATE: { name: 'createdDate', detailMain: true, columnIndex: 10, dateFormat: 'MMM dd, yyyy' },
			ACTION_STATUS: { name: 'actionStatus', detailMain: true},
			CA_STATUS: { name: 'caStatus', detailMain: true},
			EFF_CHECK_STATUS: { name: 'effCheckStatus', detailMain: true},
			CHECKLIST_STATUS: { name: 'checklistStatus', detailMain: true}
		};

	public static readonly FIELDS_INCIDENT: {
    		[key: string]: FieldDefinition
    	} = {
    			INCIDENT_NUMBER: { name: 'incidentRef', detailMain: true, columnIndex: 1 },
    			INCIDENT_STATUS: { name: 'incidentStatus', detailMain: true, columnIndex: 2 },
    			DOC_TYPE: { name: 'docType', detailMain: true, columnIndex: 3 },
    			SECTION_NAME: { name: 'sectionName', detailMain: true, columnIndex: 4 },
    			CA_NUMBER: { name: 'caRef', detailMain: true, columnIndex: 5 },
    			ACTION_NUMBER: { name: 'actionRef', detailMain: true, columnIndex: 6 },
    			EFF_CHECK_NUMBER: { name: 'effCheckRef', detailMain: true, columnIndex: 7 },
    			FILE_NAME: { name: 'filename', detailMain: true, columnIndex: 8 },
    			CREATION_DATE: { name: 'createdDate', detailMain: true, columnIndex: 9, dateFormat: 'MMM dd, yyyy' },
    			ACTION_STATUS: { name: 'actionStatus', detailMain: true},
    			CA_STATUS: { name: 'caStatus', detailMain: true},
    			EFF_CHECK_STATUS: { name: 'effCheckStatus', detailMain: true},
    			CHECKLIST_STATUS: { name: 'checklistStatus', detailMain: true}
    		};

	public static readonly FIELDS_COMPLAINT: {
    		[key: string]: FieldDefinition
    	} = {
    			COMPLAINT_NUMBER: { name: 'complaintRef', detailMain: true, columnIndex: 1 },
    			COMPLAINT_STATUS: { name: 'complaintStatus', detailMain: true, columnIndex: 2 },
    			DOC_TYPE: { name: 'docType', detailMain: true, columnIndex: 3 },
    			SECTION_NAME: { name: 'sectionName', detailMain: true, columnIndex: 4 },
    			CA_NUMBER: { name: 'caRef', detailMain: true, columnIndex: 6 },
    			ACTION_NUMBER: { name: 'actionRef', detailMain: true, columnIndex: 7 },
    			EFF_CHECK_NUMBER: { name: 'effCheckRef', detailMain: true, columnIndex: 8 },
    			FILE_NAME: { name: 'filename', detailMain: true, columnIndex: 9 },
    			CREATION_DATE: { name: 'createdDate', detailMain: true, columnIndex: 10, dateFormat: 'MMM dd, yyyy' },
    			ACTION_STATUS: { name: 'actionStatus', detailMain: true},
    			CA_STATUS: { name: 'caStatus', detailMain: true},
    			EFF_CHECK_STATUS: { name: 'effCheckStatus', detailMain: true},
    			CHECKLIST_STATUS: { name: 'checklistStatus', detailMain: true}
    		};
}
