export class Constants {

	static readonly CONFIG = {
		API: {
			PATH: 'api.path',
			ID: 'api.id'
		},
		LOG: {
			LEVEL: 'log.level'
		},
		GDPR: 'gdpr'
	};

	static readonly FILE = {
		EXTENSION: {
			JSON: 'json',
			JPEG: 'jpeg',
			PDF: 'pdf'
		}
	};

	static readonly LANG = {
		EN: 'en',
		FR: 'fr'
	};

	static readonly IMAGE = {
		UPLOAD: {
			MAX: {
				WIDTH: 1280,
				HEIGHT: 1024
			}
		}
	};
}
