import { Injectable, Injector } from '@angular/core';
import { ApiResult } from '../../../models/api-result';
import { BaseService } from '../base-service';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {

	constructor(protected readonly injector: Injector) {
		super(injector, 'ApiService');
	}

	/**
	 * Extract aggregations values.
	 *
	 * @param {ApiResult<any>} result
	 * @returns {((string | number)[])}
	 * @memberof ApiService
	 */
	public extractAggregation(result: ApiResult<any>): (string | number)[] {
		if (result && result.aggregations) {
			return result.aggregations.uniq_field.buckets.map(b => b.key);
		}
		this.logger.warn('No aggregation found for result', result);
		return [];
	}

	/**
	 * Extract aggregations values.
	 *
	 * @param {ApiResult<any>} result
	 * @returns {((string | number)[])}
	 * @memberof ApiService
	 */
	public extractAsStringAggregation(result: ApiResult<any>): (string | number)[] {
		if (result && result.aggregations) {
			return result.aggregations.uniq_field.buckets.map(b => b.key_as_string);
		} 
		this.logger.warn('No aggregation found for result', result);
		return [];
	}
}
