import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AuditorAssessmentFilterModel } from '../../../components/auditor-assessment/aa-filter/aa-filter.component';
import { WebServices } from '../../../constants/webservices';
import { ApiResult } from '../../../models/api-result';
import { HttpService } from '../../core/http/http.service';
import { BaseService } from '../base-service';
import { ApiService } from '../tech/api.service';

@Injectable()
export class AuditorAssessmentService extends BaseService {


	// Injections
	private readonly httpSrv = this.injector.get<HttpService>(HttpService);
	private readonly apiSrv = this.injector.get<ApiService>(ApiService);
	private readonly webService = WebServices.AUDITOR_ASSESSMENT;

	constructor(protected readonly injector: Injector) {
		super(injector, 'AuditorAssessmentService');
	}

	public getFilterData(field: string, nonevalue?: string | number): Observable<(string | number)[]> {
		const req = WebServices.parametrize(this.webService.GET_FILTERDATA, field);
		return this.httpSrv.call(req).pipe(
			// Extract data
			map((res: ApiResult<any>) => this.apiSrv.extractAggregation(res)),
			// Add none value
			map((res: (string | number)[]) => nonevalue !== undefined ? [nonevalue, ...res] : res)
		)
		
	}

	public getAll(filters: AuditorAssessmentFilterModel): Observable<ApiResult<any>> {
		const reqParametrized = WebServices.parametrize(this.webService.GET_ALL,filters);
		return this.httpSrv.call(reqParametrized);
	}

	public apiResultToModels(res: ApiResult<any>): any[] {
		return res.hits.hits.map(hit => Object.assign({ _id: hit._id }, hit._source));
	}

	public download(doc: any): Observable<string> {
		return this.httpSrv.call(WebServices.parametrize(this.webService.GET_DOWNLOAD,doc));
	}
}
