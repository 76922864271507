import { Injectable, Injector } from "@angular/core";
import { map, Observable } from "rxjs";
import { EtqFilterModel } from "../../../components/etq/etq-filter/etq-filter.component";
import { WebServices } from "../../../constants/webservices";
import { ApiResult } from "../../../models/api-result";
import { EtqDocumentModel } from "../../../models/etq/etq-document.model";
import { EtqDocumentTypeEnum } from "../../../models/etq/etq-document-type.enum";
import { HttpService } from "../../core/http/http.service";
import { BaseService } from "../base-service";
import { ApiService } from "../tech/api.service";
import { ObjectUtils } from "@bv/angular-commons";

@Injectable()
export class EtqService extends BaseService {
	// Injections
	private readonly httpSrv = this.injector.get<HttpService>(HttpService);
	private readonly apiSrv = this.injector.get<ApiService>(ApiService);

	constructor(protected readonly injector: Injector) {
		super(injector, "EtqService");
	}

	public getFilterData(
		type: EtqDocumentTypeEnum,
		field: string,
		nonevalue?: string | number
	): Observable<(string | number)[]> {
		const req =
			type === EtqDocumentTypeEnum.AUDIT
				? WebServices.parametrize(WebServices.ETQ_AUDIT.GET_FILTERDATA, field)
				: type === EtqDocumentTypeEnum.INCIDENT
					? WebServices.parametrize(WebServices.ETQ_INCIDENT.GET_FILTERDATA, field)
					: WebServices.parametrize(WebServices.ETQ_COMPLAINT.GET_FILTERDATA, field);
		return this.httpSrv.call(req).pipe(
			// Extract data
			map((res: ApiResult<any>) => this.apiSrv.extractAggregation(res)),
			// Add none value
			map((res: (string | number)[]) =>
				nonevalue !== undefined ? [nonevalue, ...res] : res
			)
		);
	}

	public getAll(
		type: EtqDocumentTypeEnum,
		filters: EtqFilterModel
	): Observable<ApiResult<any>> {
		const req =
			type === EtqDocumentTypeEnum.AUDIT
				? WebServices.parametrize(WebServices.ETQ_AUDIT.GET_ALL, filters)
				: type === EtqDocumentTypeEnum.INCIDENT
					? WebServices.parametrize(WebServices.ETQ_INCIDENT.GET_ALL, filters)
					: WebServices.parametrize(WebServices.ETQ_COMPLAINT.GET_ALL, filters);
		return this.httpSrv.call(req);
	}
	
	public apiResultToModels(res: ApiResult<any>): any[] {

		return res.hits.hits.sort((a,b)=> (
			(ObjectUtils.readProperty(a._source, "auditRef") ?? "").localeCompare((ObjectUtils.readProperty(b._source, "auditRef") ?? ""))
			|| (ObjectUtils.readProperty(a._source, "incidentRef") ?? "").localeCompare((ObjectUtils.readProperty(b._source, "incidentRef") ?? ""))
			|| (ObjectUtils.readProperty(a._source, "complaintRef") ?? "").localeCompare((ObjectUtils.readProperty(b._source, "complaintRef") ?? ""))
			|| (ObjectUtils.readProperty(a._source, "checklistRef") ?? "").localeCompare((ObjectUtils.readProperty(b._source, "checklistRef") ?? ""))
			|| (ObjectUtils.readProperty(a._source, "caRef") ?? "").localeCompare((ObjectUtils.readProperty(b._source, "caRef") ?? ""))
			|| (ObjectUtils.readProperty(a._source, "actionRef") ?? "").localeCompare((ObjectUtils.readProperty(b._source, "actionRef") ?? ""))
			|| (ObjectUtils.readProperty(a._source, "effCheckRef") ?? "").localeCompare((ObjectUtils.readProperty(b._source, "effCheckRef") ?? ""))
			|| ObjectUtils.readProperty(a._source, "docType").localeCompare(ObjectUtils.readProperty(b._source, "docType"))
			|| ObjectUtils.readProperty(a._source, "sectionName").localeCompare(ObjectUtils.readProperty(b._source, "sectionName"))
			))
		.map((hit) => {
			const res = hit._source;
			res.id = hit._id;
			return res;
		});
		/*
		return res.hits.hits.map((hit) => {
			const res = hit._source;
			res.id = hit._id;
			return res;
		});
		*/
	}

	public download(type: EtqDocumentTypeEnum, doc: any): Observable<string> {
		const req =
			type === EtqDocumentTypeEnum.AUDIT
				? WebServices.parametrize(WebServices.ETQ_AUDIT.GET_DOWNLOAD, doc)
				: type === EtqDocumentTypeEnum.INCIDENT
					? WebServices.parametrize(WebServices.ETQ_INCIDENT.GET_DOWNLOAD, doc)
					: WebServices.parametrize(WebServices.ETQ_COMPLAINT.GET_DOWNLOAD, doc);
		return this.httpSrv.call(req);
	}

}
