import {
	Component,
	EventEmitter,
	Injector,
	OnInit,
	Output,
} from "@angular/core";
import { StringUtils } from "@bv/angular-commons";
import { BvAlertController } from "@bv/ionic";
import { TranslateService } from "@ngx-translate/core";
import { map, Observable } from "rxjs";
import { ShirleyJobService } from "src/app/providers/services/business/shirley-job.service";
import { ShirleyConstants } from "../../../constants/shirley";
import { BaseComponent } from "../../base-component";

export class ShirleyFilterModel {
	public job_number: string | undefined = undefined;
	public office: string | undefined = undefined;
	public port: string | undefined = undefined;
	public customer: string | undefined = undefined;
	public vessel: string | undefined = undefined;
	public product: string | undefined = undefined;
	public terminal: string | undefined = undefined;
	public status: string | undefined = undefined;
	public cargo_operation: string | undefined = undefined;
	public creation_date_from: string | undefined = undefined;
	public creation_date_until: string | undefined = undefined;
}

@Component({
	selector: "app-shirley-filter",
	templateUrl: "shirley-filter.component.html",
})
export class ShirleyFilterComponent extends BaseComponent implements OnInit {
	// Injections
	public readonly shirleyJobSrv =
		this.injector.get<ShirleyJobService>(ShirleyJobService);
	private readonly alertCtrl =
		this.injector.get<BvAlertController>(BvAlertController);
	private readonly translateSrv =
		this.injector.get<TranslateService>(TranslateService);

	// Input/Output
	@Output() public search = new EventEmitter();

	public offices: Observable<string[]> | undefined = undefined;
	public statuss: Observable<string[]> | undefined = undefined;
	public cargo_operations: Observable<string[]> | undefined = undefined;
	public model = new ShirleyFilterModel();

	public readonly NONEVALUES = {
		STRING: "NONE",
		NUMBER: -9999,
	};

	constructor(protected readonly injector: Injector) {
		super(injector, "ShirleyFilterModel");
	}

	ngOnInit(): void {
		const offices = this.shirleyJobSrv.getFilterData(
			ShirleyConstants.FIELDS.OFFICE
		);
		const statuss = this.shirleyJobSrv.getFilterData(
			ShirleyConstants.FIELDS.JOB_STATUS
		);
		const cargo_operations = this.shirleyJobSrv.getFilterData(
			ShirleyConstants.FIELDS.CARGO_OPERATION
		);
		this.init(offices, statuss, cargo_operations);

		this.model.status = this.NONEVALUES.STRING;
		this.model.cargo_operation = this.NONEVALUES.STRING;
	}

	private init(
		offices: Observable<any[]>,
		statuss: Observable<any[]>,
		cargo_operations: Observable<any[]>
	): void {
		this.offices = offices.pipe(
			map((arr: string[]) => [this.NONEVALUES.STRING, ...arr])
		);
		this.statuss = statuss.pipe(
			map((arr: string[]) => [this.NONEVALUES.STRING, ...arr])
		);
		this.cargo_operations = cargo_operations.pipe(
			map((arr: string[]) => [this.NONEVALUES.STRING, ...arr])
		);
	}

	public onClickSearch(): void {
		// Update none values
		this.model.office =
			this.model.office === this.NONEVALUES.STRING
				? undefined
				: this.model.office;
		this.model.cargo_operation =
			this.model.cargo_operation === this.NONEVALUES.STRING
				? undefined
				: this.model.cargo_operation;
		this.model.status =
			this.model.status === this.NONEVALUES.STRING
				? undefined
				: this.model.status;
		this.logger.debug("onClickSearch", this.model);
		// If no filter defined
		if (this.filterIsEmpty()) {
			/*this.alertCtrl
				.create({
					title: this.translateSrv.instant(
						"common.filter.nofilters.alert.title"
					),
					message: this.translateSrv.instant(
						"common.filter.nofilters.alert.message"
					),
					buttons: [
						{
							text: this.translateSrv.instant(
								"common.filter.nofilters.alert.button"
							),
							cssClass: "primary",
						},
					],
				})
				.present();*/

			// FIXME : How to replace "present" function ?
			this.alertCtrl.create({
				header: this.translateSrv.instant(
					"common.filter.nofilters.alert.title"
				),
				message: this.translateSrv.instant(
					"common.filter.nofilters.alert.message"
				),
				buttons: [
					{
						text: this.translateSrv.instant(
							"common.filter.nofilters.alert.button"
						),
						cssClass: "primary",
					},
				],
			});
		} else {
			this.search.emit(this.model);
		}
	}

	private filterIsEmpty(): boolean {
		return (
			StringUtils.isBlank(this.model.job_number) &&
			(StringUtils.isBlank(this.model.cargo_operation) ||
				this.model.cargo_operation === this.NONEVALUES.STRING) &&
			(StringUtils.isBlank(this.model.status) ||
				this.model.status === this.NONEVALUES.STRING) &&
			StringUtils.isBlank(this.model.port) &&
			(StringUtils.isBlank(this.model.office) ||
				this.model.office === this.NONEVALUES.STRING) &&
			StringUtils.isBlank(this.model.vessel) &&
			StringUtils.isBlank(this.model.product) &&
			StringUtils.isBlank(this.model.terminal) &&
			StringUtils.isBlank(this.model.customer) &&
			StringUtils.isBlank(this.model.creation_date_from) &&
			StringUtils.isBlank(this.model.creation_date_until)
		);
	}

	public onChange(event: string, field: string): void {
		this.logger.debug("onChange", event, field);
		if (event === this.NONEVALUES.STRING) {
			this.logger.debug("onChange" + field, event);
			(this.model as any)[field] = undefined;
		}
	}
}
