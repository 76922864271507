import { Injectable } from '@angular/core';
import { StringUtils } from '@bv/angular-commons';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class HeaderService {

	private readonly changePage$ = new Subject<string>();
	private readonly currentPage$ = new BehaviorSubject<string | undefined>(undefined);

	public onPageChanged(): Observable<string> {
		return this.changePage$;
	}

	public changePage(page: string): void {
		if (!StringUtils.equalsIgnoreCase(page, this.currentPage$.getValue())) {
			console.warn('change page', page);
			this.changePage$.next(page);
			this.currentPage$.next(page);
		}
	}

	public getCurrentPage(): Observable<string | undefined> {
		return this.currentPage$;
	}

	public initFirstpage(page: string): void {
		if (!this.currentPage$.getValue()) {
			this.currentPage$.next(page);
		}
	}
}
