import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { ArrayUtils, DateUtils, ObjectUtils } from '@bv/angular-commons';
import { ApiResult } from '../models/api-result';
import { FieldDefinition, FieldDefinitions } from '../models/field-definition';
import { BaseComponent } from './base-component';
import * as XLSX from 'xlsx';

@Component({
	template: ''
})
export abstract class BasePageComponent extends BaseComponent implements OnInit {


	public lines?: any[];
	public columns?: FieldDefinition[];
	public columnSize = '10%';
	public openedRow?: number;
	public apiResult?: ApiResult<any>;

	// Private
	private readonly datePipe = new DatePipe(this.locale);

	constructor(
		protected readonly injector: Injector,
		@Inject('locale') protected locale: string,
		@Inject('loggerName') protected readonly loggerName = 'BasePageComponent') {
		super(injector, loggerName);
	}

	ngOnInit(): void {
		this.initColumns();
	}

	private initColumns(): void {
		let fieldsColumns: FieldDefinition[] = this.getFieldDefinitions()
			// Keep only field displayed like column
			.filter((field: FieldDefinition) => field.columnIndex !== undefined);
		// Sort by column position
		fieldsColumns = ArrayUtils.sort(fieldsColumns, {sortBy: 'columnIndex'});
		// Calc column size
		this.columnSize = `${100 / (fieldsColumns.length + 1)}%`;
		// Set columns
		this.columns = fieldsColumns;
	}

	protected setLines(newlines: any[]): void {
		this.lines = (newlines || []).map(line => this.formatLine(line));
	}

	private formatLine(line: any): any {
		const clone = Object.assign({}, line);
		// For each field
		this.getFieldDefinitions()
			.forEach(field => {
				// If is a date field
				const value = ObjectUtils.readProperty(clone, field.name);
				this.logger.debug('field', field, value);
				this.logger.debug('field DateUtils.isDate(value)', DateUtils.isDate(value));
				this.logger.debug('field DateUtils.isDateString(value)', DateUtils.isDateString(value));
				if (DateUtils.isDate(value) || DateUtils.isDateString(value)) {
					// Format date
					const dateValue = this.datePipe.transform(value, this.getDateFormat());
					ObjectUtils.writeProperty(clone, field.name, dateValue);
				}
			});
		return clone;
	}

	private getFieldDefinitions(): FieldDefinition[] {
		const fields = this.getFields();
		return Object.keys(fields)
			.map(key => ObjectUtils.readProperty(fields, key));
	}

	protected abstract getFields(): FieldDefinitions;

	protected getDateFormat(): string {
		return 'yyyy-MM-dd H:mm:ss  a zzzz';
	}

	public onClickRow(index: number): void {
		this.openedRow = this.openedRow === index ? undefined : index;
	}

	public onSearch(event: any): void {
		this.openedRow = undefined;
		this.load(event);
	}

    public exportToExcel(data: any, filename: string) {
  		const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(data);
  		const wb: XLSX.WorkBook = XLSX.utils.book_new();
  		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
  		/* save to file */
  		XLSX.writeFile(wb, filename);
	}

	protected abstract load(filterModel: any): void;

}
