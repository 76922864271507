import { Component, Injector, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Constants } from 'src/app/app.constants';
import { CdmConstants } from '../../../constants/cdm';
import { WebServices } from '../../../constants/webservices';
import { CdmDocumentTypeEnum } from '../../../models/cdm/cdm-document-type.enum';
import { FieldDefinitions } from '../../../models/field-definition';
import { Config } from '../../../providers/core/config/config';
import { CdmService } from '../../../providers/services/business/cdm.service';
import { UrlUtils } from '../../../utils/url-utils';
import { BaseMetadataComponent } from '../../base-metadata-component';


@Component({
	selector: 'page-cdm-metadata',
	templateUrl: 'cdm-metadata.component.html'
})
export class CdmMetadataComponent extends BaseMetadataComponent {

	// Injections
	private readonly viewCtrl = this.injector.get<ModalController>(ModalController);
	private readonly config = this.injector.get<Config>(Config);
	private readonly cdmSrv = this.injector.get<CdmService>(CdmService);

	// Public
	@Input()
	public type?: CdmDocumentTypeEnum;

	// Public
	public translations = new Map<string, string>();
	public downloadUrl?: string;

	constructor(protected readonly injector: Injector) {
		super(injector, 'CdmMetadataComponent');
	}

	protected init(): void {
		const req = CdmDocumentTypeEnum.PROJECT
			? WebServices.parametrize(WebServices.CDM_PROJECT.GET_DOWNLOAD, this.model)
			: WebServices.parametrize(WebServices.CDM_PROPOSAL.GET_DOWNLOAD, this.model);
		const url = req.url;
		this.downloadUrl = UrlUtils.concat(this.config.get(Constants.CONFIG.API.PATH), url);
	}

	public onClickClose(): void {
		this.viewCtrl.dismiss();
	}

	public onClickLink(): void {
		if (!this.type) {
			throw new Error('Type not defined');
		}
		this.cdmSrv
			.download(this.type, this.model)
			.subscribe((response: string) => {
				this.logger.debug('Download url', response);
				console.log(response);
				window.open(JSON.parse(JSON.stringify(response)).url);
			});
	}

	protected getFields(): FieldDefinitions {
		return CdmDocumentTypeEnum.PROJECT === this.type
			? CdmConstants.FIELDS_PROJECT
			: CdmDocumentTypeEnum.PROPOSAL === this.type
				? CdmConstants.FIELDS_PROPOSAL
				: {};
	}

}
