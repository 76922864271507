import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { ObjectUtils } from '@bv/angular-commons';
import { FieldDefinition, FieldDefinitions } from '../models/field-definition';
import { BaseComponent } from './base-component';

@Component({
	template: ''
})
export abstract class BaseMetadataComponent extends BaseComponent implements OnInit {

	// Input/Output
	@Input()
	public document?: any;

	// Public
	public model?: any;
	public fields?: FieldDefinition[];
	public show = {
		buttonShowMore: true,
		more: false
	};

	constructor(
		protected readonly injector: Injector,
		@Inject('loggerName') protected readonly loggerName = 'BaseMetadataComponent') {
		super(injector, loggerName);
	}

	ngOnInit(): void {
		this.logger.debug('input document', this.document);
		if (!this.document) {
			return;
		}
		this.fields = this.getFilteredField();
		this.show.buttonShowMore = this.fields.filter(f => f.detailOther).length > 0;
		this.logger.debug('fields', this.fields);
		this.model = Object.assign({}, this.document);
		this.init();
	}

	protected abstract init(): void;

	private getFilteredField(): FieldDefinition[] {
		const fields = this.getFields();
		return Object.keys(fields)
			.map(key => ObjectUtils.readProperty(fields, key))
			// Keep only field displayed in details
			.filter((field: FieldDefinition) => field.detailMain || field.detailOther);
	}

	public showMoreMetadata(): void {
		this.show.more = true;
	}

	public showLessMetadata(): void {
		this.show.more = false;
	}

	protected abstract getFields(): FieldDefinitions;
}
