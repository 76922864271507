import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageConstants } from "src/app/constants/pages";
import { HomePage } from "./home.component";

const routes: Routes = [
    {path: '', component:HomePage, children: [
		{
			path: '',
			loadChildren: () =>
				import("../gsit/gsit-home/gsit-home.module").then(
				(mod) => mod.GsitHomePageModule
				)
		}
	]}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class HomeRoutingModule {}
	