import { Component, Injector } from '@angular/core';
import { BasePage } from '../base-page';

@Component({
	selector: 'page-home',
	templateUrl: 'home.component.html'
})
export class HomePage extends BasePage {

	constructor(protected injector: Injector) {
		super(injector, 'HomePage');
	}
}
