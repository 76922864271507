import { Component, Injector, ViewChild } from '@angular/core';
import { LoaderUtils } from '@bv/ionic';
import { map, Observable } from "rxjs";
import { ZigConstants } from '../../../constants/zig';
import { ApiResult } from '../../../models/api-result';
import { FieldDefinitions } from '../../../models/field-definition';
import { ZigDocumentModel } from '../../../models/zig/zig-doc-document.model';
import { ZigService } from '../../../providers/services/business/zig.service';
import { TranslateService } from '@ngx-translate/core';
import { BasePageComponent } from '../../base-page-component';
import { ZigFilterModel } from '../zig-filter/zig-filter.component';
import { IonModal } from '@ionic/angular';
import { MessageService } from "../../../providers/core/message.service";
import { BvAlertController } from '@bv/ionic';

@Component({
    selector: 'page-zig-doc',
    templateUrl: 'zig-doc.component.html'
  })
  export class ZigDocComponent extends BasePageComponent {
    
    //injections
    private readonly alertCtrl = this.injector.get<BvAlertController>(BvAlertController);
    public readonly zigSrv = this.injector.get<ZigService>(ZigService);
    private readonly messageSrv = this.injector.get(MessageService);
    private readonly translateSrv = this.injector.get<TranslateService>(TranslateService);

    @ViewChild(IonModal) modal!: IonModal;
    
    public zipDownloadURL = "";
    public model: ZigFilterModel = new ZigFilterModel();
    public invoicing_years: Observable<String[]> | undefined = undefined;

    public readonly NONEVALUES = {
      STRING: 'NONE',
      NUMBER: -9999
    };

    public modalIsForCompletedExport = false;

    constructor(protected injector: Injector) {
      super(injector, 'ZigDocComponent');
    }
    
    protected load(filter: ZigFilterModel): void {
      // Start loading
      this.model = filter;
      const invoicing_years: Observable<any[]> = this.zigSrv.getExportFilterData(ZigConstants.FIELDS.INVOICING_DATE);
      this.invoicing_years = invoicing_years.pipe(map((arr: string[]) => [this.NONEVALUES.STRING, ...arr]));
      this.model.invoicingDate = this.NONEVALUES.STRING;
     
      LoaderUtils.presentLoading(this.injector).then(loading => {
        // Load data
        this.zigSrv.getAll(filter)
          .subscribe(
            (result: ApiResult<ZigDocumentModel>) => {
              this.apiResult = result;
              this.lines = this.zigSrv.apiResultToModels(result);
              // Stop loading
              loading.dismiss();
            },
            (error: any) => {
              this.logger.error('Error during loading', error);
              // Stop loading
              loading.dismiss();
            }
          );
      });
    }

    public onChange(event: string, field: string): void {
      if (event === this.NONEVALUES.STRING) {
          this.logger.debug('onChange' + field, event);
          (this.model as any)[field] = undefined;
      }
    }

    cancelModal() {
      this.modal.dismiss(null, 'cancel');
    }
    copyToClipboard() {
      navigator.clipboard.writeText(this.zipDownloadURL).then( () => {
        this.messageSrv.showSuccess({ messageKey: "common.exportzip.copytoclipboardsuccess" })
      }).catch( () => {
        this.messageSrv.showError({ messageKey: "common.exportzip.copytoclipboardfailed" });
      });
    }
    downloadZip() {
      this.modal.dismiss(null, 'download');
      window.open(this.zipDownloadURL);
    }

    protected getFields(): FieldDefinitions {
      return ZigConstants.FIELDS_ZIG;
    }

    public exportResultsToZip() {
      this.modalIsForCompletedExport = true;
      const itemsFilenames = (this.lines || []).map((item:any) => { 
        return String(item?.id)
        });
      // Start loading
      LoaderUtils.presentLoading(this.injector).then(loading => {
            let obs: Observable<string> | undefined = undefined;
            obs = this.zigSrv.zipResultsAndDownload(itemsFilenames);
            if (obs) {
              obs.subscribe((response: string) => {
                // Stop loading
                loading.dismiss();
                this.zipDownloadURL = JSON.parse(JSON.stringify(response)).url;
                this.modal.present();
              },
              (error: any) => {
                this.logger.error('Error during export', error);
                this.messageSrv.showError({ messageKey: "common.exportzip.failed" });
                // Stop loading
                loading.dismiss();
              });
            }
      });
    }
}