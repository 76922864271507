import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { LoggerFactory } from '@bv/angular-logger';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, Observable } from 'rxjs';
import { forkJoin, map } from 'rxjs';
import { Constants } from 'src/app/app.constants';

@Injectable({ providedIn: 'root' })
export class CustomTranslateLoader implements TranslateLoader {

	private readonly I18N_DIR = '../assets/i18n/';

	private readonly logger = this.injector.get(LoggerFactory).buildLogger('CustomTranslateLoader');

	// Add in this array all files to load from folder'I18N_DIR'
	private readonly I18N_FILES = [
		'cdm',
		'common',
		'error',
		'gsit',
		'login',
		'ismpurchase',
		'aa',
		'shirley',
		'sequence6',
		'awi',
		'etq',
		'zig'
	];

	constructor(private readonly http: HttpClient, private injector: Injector) { }

	public getTranslation(lang: string): Observable<any> {
		this.logger.debug('Translate : ', lang);
		const langs = forkJoin(this.getObservables(lang));

		return langs.pipe(
			map((res: any[]) => {
				return res.reduce((prev, curr) => {
					return Object.assign(prev, curr);
				}, {})
			})
		);
	}

	private getObservables(lang: string): Observable<any>[] {
		// Uncomment to load file like './assets/i18n/en.json'
		const obs: Observable<Object>[] = [];
		this.I18N_FILES.forEach(o => {
			obs.push(this.loadFile(lang, o));
		});
		return obs;
	}

	private loadFile(lang: string, fileName: string): Observable<Object> {
		const file = this.getPath(lang, fileName);
		return this.http.get(file).pipe(catchError(err => {
			// Get default lang
			return this.getPath(Constants.LANG.EN, fileName);
		}));
	}

	private getPath(lang: string, fileName: string): string {
		return `${this.I18N_DIR}${lang}/${fileName}.${Constants.FILE.EXTENSION.JSON}`;
	}
}
