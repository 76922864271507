import { Injectable, Injector } from '@angular/core';
import { ObjectUtils } from '@bv/angular-commons';
import { LoggerConfig, LogLevel } from '@bv/angular-logger';
import { LogHttp } from '@bv/angular-logger/lib/config/logger-config';

interface InterfaceConfig {
	log: {
		level: LogLevel,
		http: LogHttp
	};
}

@Injectable({providedIn: 'root'})
export class Config {

	private config: InterfaceConfig | undefined;

	// Injections
	private readonly loggerConfig = this.injector.get(LoggerConfig);

	constructor(private readonly injector: Injector) { }

	public getAll(): any {
		return this.config;
	}

	public get(path: string): any {
		let searchedValue = this.config;
		path.split(".").forEach((key) => {
            const value = ObjectUtils.readProperty(searchedValue, key);
            searchedValue = ObjectUtils.isDefined(value) ? value : undefined;
        });
        return searchedValue;
	}

	public set(config: InterfaceConfig) {
		this.loggerConfig.setLogLevel(config.log.level);
		console.log('config.log.level', config.log.level);
		this.loggerConfig.setLogHttp(config.log.http);
		console.log('config.log.http', config.log.http);
		this.config = config;
	}

}
