import { Component, Injector, Input } from '@angular/core';
import { ApiResult } from '../../models/api-result';
import { BaseComponent } from '../base-component';

@Component({
	selector: 'too-many-results',
	templateUrl: 'too-many-results.component.html',
	styleUrls: ['too-many-results.component.scss']
})
export class TooManyResultsComponent extends BaseComponent {

	@Input()
	public set apiResult(result: ApiResult<any>) {
		this.show = result && result.hits && result.hits.hits && result.hits.total > result.hits.hits.length
			? true
			: false;
	}

	public show = false;

	constructor(protected readonly injector: Injector) {
		super(injector, 'TooManyResultsComponent');
	}

}
